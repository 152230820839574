import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ValidatedInput from 'app/shared/components/validated-input';
import {
  ITaskBusinessForm,
  IToaTask,
  FormBusinessGroup,
  VariableNames,
  VariableValueTypes,
  FormHeaderProperties,
} from 'app/shared/model/task.model';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, CardTitle, Container, Form, FormGroup, Row } from 'reactstrap';
import '../style.scss';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import moment from 'moment';
import { completeTask } from '../tasks.reducer';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import { renderToString } from 'react-dom/server';

const FormCard = () => {
  const dispatch = useAppDispatch();
  const task: IToaTask = useAppSelector(state => state.task.entity);
  const updateSuccess = useAppSelector(state => state.task.updateSuccess);
  const formData: ITaskBusinessForm = task.businessForm;
  const locale = useAppSelector(state => state.locale.currentLocale);
  const translateTimeElement = <Translate contentKey="global.time">Time</Translate>;
  const convertTimeElementToString = renderToString(translateTimeElement);
  const timeTranslateReplace = convertTimeElementToString.replace('<span>', '');
  const timeReplaceFinal = timeTranslateReplace.replace('</span>', '');

  const completeManual = (businessForm: ITaskBusinessForm) => {
    const payload = {
      toa: { id: task.toa.id },
      businessForm: {
        ...businessForm,
      },
      variables: {
        name: [VariableNames.retry, VariableNames.escalate],
        value: [false, false],
        valueType: [VariableValueTypes.boolean, VariableValueTypes.boolean],
      },
    };

    dispatch(completeTask({ id: task.id, payload }));
  };

  const changeTypestoInput = (type: string) => {
    const numbers = ['number', 'float', 'long', 'integer'];
    switch (true) {
      case type === 'localdate':
        return 'date';
        break;
      case type === 'instant':
        return 'datetime-local';
        break;
      case type === 'boolean':
        return 'checkbox';
        break;
      case numbers.includes(type):
        return 'number';
        break;
        return 'text';
    }
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset: formReset,
  } = useForm({
    mode: 'onChange',
  });

  const watchExecutionResult = watch('executionResult');

  //userIdentity
  const watchIamStaticReturnedDateNextChangePassword = watch('returnedDateNextChangePassword');
  const watchReturnedMustChangePasswordOnFirstLogin = watch('returnedMustChangePasswordOnFirstLogin');
  const watchReturnedAlternativeUserName = watch('returnedAlternativeUserName');
  const watchReturnedBusinessValidityEndOrderedUserIdentity = watch('returnedBusinessValidityEndOrderedUserIdentity');
  const watchReturnedUserName = watch('returnedUserName');
  const watchReturnedInitialPassword = watch('returnedInitialPassword');
  const watchReturnedDisplayName = watch('returnedDisplayName');
  const watchReturnedBusinessValidityStartOrderedUserIdentity = watch('returnedBusinessValidityStartOrderedUserIdentity');

  //roles
  const watchReturnedBusinessValidityStartOrderedRole = watch('returnedBusinessValidityStartOrderedRole');
  const watchReturnedBusinessValidityEndOrderedRole = watch('returnedBusinessValidityEndOrderedRole');

  //groups
  const watchReturnedBusinessValidityStartOrderedGroup = watch('returnedBusinessValidityStartOrderedGroup');
  const watchReturnedBusinessValidityEndOrderedGroup = watch('returnedBusinessValidityEndOrderedGroup');

  //specificPermmission
  const watchReturnedBusinessValidityStartOrderedSpecificPermission = watch('returnedBusinessValidityStartOrderedSpecificPermission');
  const watchReturnedBusinessValidityEndOrderedSpecificPermission = watch('returnedBusinessValidityEndOrderedSpecificPermission');

  const submitForm = values => {
    const userIdentity = {
      iamStaticReturnedDateNextChangePassword: values.returnedDateNextChangePassword ? values.returnedDateNextChangePassword : null,
      iamStaticReturnedMustChangePasswordOnFirstLogin: values.returnedMustChangePasswordOnFirstLogin,
      iamStaticReturnedAlternativeUserName: values.returnedAlternativeUserName,
      iamStaticReturnedBusinessValidityEnd: values.returnedBusinessValidityEndOrderedUserIdentity
        ? moment(values.returnedBusinessValidityEndOrderedUserIdentity).toISOString()
        : null,
      iamStaticReturnedUserName: values.returnedUserName,
      iamStaticReturnedInitialPassword: values.returnedInitialPassword,
      iamStaticReturnedDisplayName: values.returnedDisplayName,
      iamStaticReturnedBusinessValidityStart: values.returnedBusinessValidityStartOrderedUserIdentity
        ? moment(values.returnedBusinessValidityStartOrderedUserIdentity).toISOString()
        : null,
      iamStaticReturnedAssociatedEmailAddress: values.returnedAssociatedEmailAddress,
    };

    const role = {
      iamStaticRoleReturnedBusinessValidityStart: values.returnedBusinessValidityStartOrderedRole
        ? moment(values.returnedBusinessValidityStartOrderedRole).toISOString()
        : null,
      iamStaticRoleReturnedBusinessValidityEnd: values.returnedBusinessValidityEndOrderedRole
        ? moment(values.returnedBusinessValidityEndOrderedRole).toISOString()
        : null,
    };

    const groupOrdered = {
      iamStaticGroupReturnedBusinessValidityStart: values.returnedBusinessValidityStartOrderedGroup
        ? moment(values.returnedBusinessValidityStartOrderedGroup).toISOString()
        : null,
      iamStaticGroupReturnedBusinessValidityEnd: values.returnedBusinessValidityEndOrderedGroup
        ? moment(values.returnedBusinessValidityEndOrderedGroup).toISOString()
        : null,
    };

    const specificPermission = {
      iamStaticPermReturnedBusinessValidityStart: values.returnedBusinessValidityStartOrderedSpecificPermission
        ? moment(values.returnedBusinessValidityStartOrderedSpecificPermission).toISOString()
        : null,
      iamStaticPermReturnedBusinessValidityEnd: values.returnedBusinessValidityEndOrderedSpecificPermission
        ? moment(values.returnedBusinessValidityEndOrderedSpecificPermission).toISOString()
        : null,
    };

    const entity = {
      ...formData,
      header: {
        properties: formData.header.properties.map(prop =>
          prop.name === FormHeaderProperties.responseMessage ? { ...prop, value: watchExecutionResult ? watchExecutionResult : null } : prop
        ),
      },
      groups: formData.groups.map(group => ({
        ...group,
        values: group?.values?.map(i => {
          let returnedValue;
          if (group.name === FormBusinessGroup.OrderedUserIdentityDTO) {
            for (const [key, val] of Object.entries(userIdentity)) {
              if (key === i.name) {
                returnedValue = {
                  ...i,
                  value: val ? val : null,
                };
              }
            }
          }
          if (group.name === FormBusinessGroup.OrderedRoleDTO) {
            for (const [key, val] of Object.entries(role)) {
              if (key === i.name) {
                returnedValue = {
                  ...i,
                  value: val ? val : null,
                };
              }
            }
          }
          if (group.name === FormBusinessGroup.OrderedGroupDTO) {
            for (const [key, val] of Object.entries(groupOrdered)) {
              if (key === i.name) {
                returnedValue = {
                  ...i,
                  value: val ? val : null,
                };
              }
            }
          }
          if (group.name === FormBusinessGroup.OrderedSpecificPermissionDTO) {
            for (const [key, val] of Object.entries(specificPermission)) {
              if (key === i.name) {
                returnedValue = {
                  ...i,
                  value: val ? val : null,
                };
              }
            }
          }
          for (const [key, val] of Object.entries(values)) {
            if (key === i.name) {
              returnedValue = {
                ...i,
                value: val ? val : null,
              };
            }
          }

          return returnedValue;
        }),
      })),
    };

    completeManual(entity);
  };

  useEffect(() => {
    if (task.toa?.orderedUserIdentity) {
      setValue(
        'returnedDateNextChangePassword',
        task?.toa?.orderedUserIdentity?.returnedDateNextChangePassword ? task.toa.orderedUserIdentity.returnedDateNextChangePassword : ''
      );
      setValue('returnedMustChangePasswordOnFirstLogin', task.toa.orderedUserIdentity.returnedMustChangePasswordOnFirstLogin ?? '');
      setValue('returnedAlternativeUserName', task.toa.orderedUserIdentity.returnedAlternativeUserName ?? '');
      setValue(
        'returnedBusinessValidityEndOrderedUserIdentity',
        task?.toa?.orderedUserIdentity?.returnedBusinessValidityEnd
          ? moment(task.toa.orderedUserIdentity.returnedBusinessValidityEnd).format(convertDateFormat('en', 'instant')).toString()
          : ''
      );
      setValue('returnedUserName', task.toa.orderedUserIdentity.returnedUserName ?? '');
      setValue('returnedInitialPassword', task.toa.orderedUserIdentity.returnedInitialPassword ?? '');
      setValue('returnedDisplayName', task.toa.orderedUserIdentity.returnedDisplayName ?? '');
      setValue(
        'returnedBusinessValidityStartOrderedUserIdentity',
        task?.toa?.orderedUserIdentity?.businessValidityStart
          ? moment(task.toa.orderedUserIdentity.businessValidityStart).format(convertDateFormat('en', 'instant')).toString()
          : ''
      );
      setValue('executionResult', task.toa.orderedUserIdentity.executionResult ?? '');
    }

    if (task.toa?.orderedRole) {
      setValue(
        'returnedBusinessValidityStartOrderedRole',
        task?.toa?.orderedRole?.returnedBusinessValidityStart
          ? moment(task.toa.orderedRole.returnedBusinessValidityStart).format(convertDateFormat('en', 'instant')).toString()
          : ''
      );
      setValue(
        'returnedBusinessValidityEndOrderedRole',
        task?.toa?.orderedRole?.returnedBusinessValidityEnd
          ? moment(task.toa.orderedRole.returnedBusinessValidityEnd).format(convertDateFormat('en', 'instant')).toString()
          : ''
      );
      setValue('executionResult', task.toa.orderedRole.executionResult ?? '');
    }

    if (task.toa?.orderedGroup) {
      setValue(
        'returnedBusinessValidityStartOrderedGroup',
        task?.toa?.orderedGroup?.returnedBusinessValidityStart
          ? moment(task.toa.orderedGroup.returnedBusinessValidityStart).format(convertDateFormat('en', 'instant')).toString()
          : ''
      );
      setValue(
        'returnedBusinessValidityEndOrderedGroup',
        task?.toa?.orderedGroup?.returnedBusinessValidityEnd
          ? moment(task.toa.orderedGroup.returnedBusinessValidityEnd).format(convertDateFormat('en', 'instant')).toString()
          : ''
      );
      setValue('executionResult', task.toa.orderedGroup.executionResult ?? '');
    }

    if (task.toa?.orderedSpecificPermission) {
      setValue(
        'returnedBusinessValidityStartOrderedSpecificPermission',
        task?.toa?.orderedSpecificPermission?.returnedBusinessValidityStart
          ? moment(task.toa.orderedSpecificPermission.returnedBusinessValidityStart).format(convertDateFormat('en', 'instant')).toString()
          : ''
      );
      setValue(
        'returnedBusinessValidityEndOrderedSpecificPermission',
        task?.toa?.orderedSpecificPermission?.returnedBusinessValidityEnd
          ? moment(task.toa.orderedSpecificPermission.returnedBusinessValidityEnd).format(convertDateFormat('en', 'instant')).toString()
          : ''
      );
      setValue('executionResult', task.toa.orderedSpecificPermission.executionResult ?? '');
    }

    const checkConnectorCommand = formData?.groups?.some(gr => gr.name.includes(FormBusinessGroup.ConnectorCommandAttributeDTO));
    if (checkConnectorCommand) {
      const getConnectorCommandFields = formData.groups.find(gr => gr.name === FormBusinessGroup.ConnectorCommandAttributeDTO).values;
      for (const field of getConnectorCommandFields) {
        setValue(field.name, field.value);
      }
    }
  }, [task]);

  const copyValueToInput = (input: string, value: string | boolean) => {
    if (value && input) setValue(input, value);
  };

  return (
    <Card color="white" inverse className="form-card">
      <CardHeader>
        <CardTitle className="mb-0" tag="h5">
          {translate('iamdentityApp.tasks.taskForm')}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit(submitForm)}>
          {task.toa?.orderedGroup ? (
            <React.Fragment>
              <h5>
                <Translate contentKey="iamdentityApp.orderedGroup.home.title">Ordered Role</Translate>
              </h5>
              <Container>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedGroup.businessValidityStart')}:<br />
                    {task?.toa?.orderedGroup?.businessValidityStart
                      ? moment(task.toa.orderedGroup.businessValidityStart).format(convertDateFormat(locale, 'instant'))
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityStartOrderedGroup',
                          task?.toa?.orderedGroup?.businessValidityStart
                            ? moment(task.toa.orderedGroup.businessValidityStart).format(convertDateFormat('en', 'instant')).toString()
                            : null
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div className="date-picker-container date-picker-custom-content mb-3">
                    <Controller
                      control={control}
                      name="returnedBusinessValidityStartOrderedGroup"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div className="form-group">
                          <label htmlFor="user-group-returnedBusinessValidityStartOrderedGroup" className="form-label">
                            {translate('iamdentityApp.orderedGroup.returnedBusinessValidityStart')}
                          </label>
                          <div className="position-relative">
                            <DatePicker
                              popperClassName="datepicker-calendar-popper"
                              portalId="root-portal"
                              selected={value ? new Date(value) : new Date()}
                              onChange={date => {
                                onChange(date);
                              }}
                              value={
                                value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')
                              }
                              onBlur={onBlur}
                              locale={locale === 'ro' ? ro : 'en'}
                              todayButton={<Translate contentKey="global.today">Today</Translate>}
                              showMonthDropdown
                              showYearDropdown
                              timeInputLabel={timeReplaceFinal + ':'}
                              showTimeInput
                              className="form-control"
                              ref={ref}
                              id="user-group-returnedBusinessValidityStartOrderedGroup"
                              data-cy="returnedBusinessValidityStartOrderedGroup"
                            />
                            <span
                              onClick={() => {
                                onChange(null);
                              }}
                              className="fw-semibold close-icon-custom"
                            >
                              {value ? 'X' : ''}
                            </span>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedGroup.businessValidityEnd')}:<br />
                    {task?.toa?.orderedGroup?.businessValidityEnd
                      ? moment(task.toa.orderedGroup.businessValidityEnd).format(convertDateFormat(locale, 'instant'))
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityEndOrderedGroup',
                          task?.toa?.orderedGroup?.businessValidityEnd
                            ? moment(task.toa.orderedGroup.businessValidityEnd).format(convertDateFormat('en', 'instant')).toString()
                            : null
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div className="date-picker-container date-picker-custom-content mb-3">
                    <Controller
                      control={control}
                      name="returnedBusinessValidityEndOrderedGroup"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div className="form-group">
                          <label htmlFor="user-group-returnedBusinessValidityEndOrderedGroup" className="form-label">
                            {translate('iamdentityApp.orderedGroup.returnedBusinessValidityEnd')}
                          </label>
                          <div className="position-relative">
                            <DatePicker
                              popperClassName="datepicker-calendar-popper"
                              portalId="root-portal"
                              selected={value ? new Date(value) : new Date()}
                              onChange={date => {
                                onChange(date);
                              }}
                              value={
                                value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')
                              }
                              onBlur={onBlur}
                              locale={locale === 'ro' ? ro : 'en'}
                              todayButton={<Translate contentKey="global.today">Today</Translate>}
                              showMonthDropdown
                              showYearDropdown
                              timeInputLabel={timeReplaceFinal + ':'}
                              showTimeInput
                              className="form-control"
                              ref={ref}
                              id="user-group-returnedBusinessValidityEndOrderedGroup"
                              data-cy="returnedBusinessValidityEndOrderedGroup"
                            />
                            <span
                              onClick={() => {
                                onChange(null);
                              }}
                              className="fw-semibold close-icon-custom"
                            >
                              {value ? 'X' : ''}
                            </span>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Row>
              </Container>
            </React.Fragment>
          ) : null}

          {task.toa?.orderedRole ? (
            <React.Fragment>
              <h5>
                <Translate contentKey="iamdentityApp.orderedRole.home.title">Ordered Role</Translate>
              </h5>
              <Container>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedRole.businessValidityStart')}:<br />
                    {task?.toa?.orderedRole?.businessValidityStart
                      ? moment(task.toa.orderedRole.businessValidityStart).format(convertDateFormat(locale, 'instant'))
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityStartOrderedRole',
                          task?.toa?.orderedRole?.businessValidityStart
                            ? moment(task.toa.orderedRole.businessValidityStart).format(convertDateFormat('en', 'instant')).toString()
                            : null
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div className="date-picker-container date-picker-custom-content mb-3">
                    <Controller
                      control={control}
                      name="returnedBusinessValidityStartOrderedRole"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div className="form-group">
                          <label htmlFor="user-role-returnedBusinessValidityStartOrderedRole" className="form-label">
                            {translate('iamdentityApp.orderedRole.returnedBusinessValidityStart')}
                          </label>
                          <div className="position-relative">
                            <DatePicker
                              popperClassName="datepicker-calendar-popper"
                              portalId="root-portal"
                              selected={value ? new Date(value) : new Date()}
                              onChange={date => {
                                onChange(date);
                              }}
                              value={
                                value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')
                              }
                              onBlur={onBlur}
                              locale={locale === 'ro' ? ro : 'en'}
                              todayButton={<Translate contentKey="global.today">Today</Translate>}
                              showMonthDropdown
                              showYearDropdown
                              timeInputLabel={timeReplaceFinal + ':'}
                              showTimeInput
                              className="form-control"
                              ref={ref}
                              id="user-role-returnedBusinessValidityStartOrderedRole"
                              data-cy="returnedBusinessValidityStartOrderedRole"
                            />
                            <span
                              onClick={() => {
                                onChange(null);
                              }}
                              className="fw-semibold close-icon-custom"
                            >
                              {value ? 'X' : ''}
                            </span>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedRole.businessValidityEnd')}:<br />
                    {task?.toa?.orderedRole?.businessValidityEnd
                      ? moment(task.toa.orderedRole.businessValidityEnd).format(convertDateFormat(locale, 'instant'))
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityEndOrderedRole',
                          task?.toa?.orderedRole?.businessValidityEnd
                            ? moment(task.toa.orderedRole.businessValidityEnd).format(convertDateFormat('en', 'instant')).toString()
                            : null
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div className="date-picker-container date-picker-custom-content mb-3">
                    <Controller
                      control={control}
                      name="returnedBusinessValidityEndOrderedRole"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div className="form-group">
                          <label htmlFor="user-role-returnedBusinessValidityEndOrderedRole" className="form-label">
                            {translate('iamdentityApp.orderedRole.returnedBusinessValidityEnd')}
                          </label>
                          <div className="position-relative">
                            <DatePicker
                              popperClassName="datepicker-calendar-popper"
                              portalId="root-portal"
                              selected={value ? new Date(value) : new Date()}
                              onChange={date => {
                                onChange(date);
                              }}
                              value={
                                value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')
                              }
                              onBlur={onBlur}
                              locale={locale === 'ro' ? ro : 'en'}
                              todayButton={<Translate contentKey="global.today">Today</Translate>}
                              showMonthDropdown
                              showYearDropdown
                              timeInputLabel={timeReplaceFinal + ':'}
                              showTimeInput
                              className="form-control"
                              ref={ref}
                              id="user-role-returnedBusinessValidityEndOrderedRole"
                              data-cy="returnedBusinessValidityEndOrderedRole"
                            />
                            <span
                              onClick={() => {
                                onChange(null);
                              }}
                              className="fw-semibold close-icon-custom"
                            >
                              {value ? 'X' : ''}
                            </span>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Row>
              </Container>
            </React.Fragment>
          ) : null}

          {task.toa?.orderedSpecificPermission ? (
            <React.Fragment>
              <h5>
                <Translate contentKey="iamdentityApp.orderedSpecificPermission.home.title">Ordered Specific Permission</Translate>
              </h5>
              <Container>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedSpecificPermission.businessValidityStart')}:<br />
                    {task?.toa?.orderedSpecificPermission?.businessValidityStart
                      ? moment(task.toa.orderedSpecificPermission.businessValidityStart).format(convertDateFormat(locale, 'instant'))
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityStartOrderedSpecificPermission',
                          task?.toa?.orderedSpecificPermission?.businessValidityStart
                            ? moment(task.toa.orderedSpecificPermission.businessValidityStart)
                                .format(convertDateFormat('en', 'instant'))
                                .toString()
                            : null
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div className="date-picker-container date-picker-custom-content mb-3">
                    <Controller
                      control={control}
                      name="returnedBusinessValidityStartOrderedSpecificPermission"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div className="form-group">
                          <label htmlFor="user-permission-returnedBusinessValidityStartOrderedSpecificPermission" className="form-label">
                            {translate('iamdentityApp.orderedSpecificPermission.returnedBusinessValidityStart')}
                          </label>
                          <div className="position-relative">
                            <DatePicker
                              popperClassName="datepicker-calendar-popper"
                              portalId="root-portal"
                              selected={value ? new Date(value) : new Date()}
                              onChange={date => {
                                onChange(date);
                              }}
                              value={
                                value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')
                              }
                              onBlur={onBlur}
                              locale={locale === 'ro' ? ro : 'en'}
                              todayButton={<Translate contentKey="global.today">Today</Translate>}
                              showMonthDropdown
                              showYearDropdown
                              timeInputLabel={timeReplaceFinal + ':'}
                              showTimeInput
                              className="form-control"
                              ref={ref}
                              id="user-permission-returnedBusinessValidityStartOrderedSpecificPermission"
                              data-cy="returnedBusinessValidityStartOrderedSpecificPermission"
                            />
                            <span
                              onClick={() => {
                                onChange(null);
                              }}
                              className="fw-semibold close-icon-custom"
                            >
                              {value ? 'X' : ''}
                            </span>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedSpecificPermission.businessValidityEnd')}:<br />
                    {task?.toa?.orderedSpecificPermission?.businessValidityEnd
                      ? moment(task.toa.orderedSpecificPermission.businessValidityEnd).format(convertDateFormat(locale, 'instant'))
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityEndOrderedSpecificPermission',
                          task?.toa?.orderedSpecificPermission?.businessValidityEnd
                            ? moment(task.toa.orderedSpecificPermission.businessValidityEnd)
                                .format(convertDateFormat('en', 'instant'))
                                .toString()
                            : null
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div className="date-picker-container date-picker-custom-content mb-3">
                    <Controller
                      control={control}
                      name="returnedBusinessValidityEndOrderedSpecificPermission"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div className="form-group">
                          <label htmlFor="user-permission-returnedBusinessValidityEndOrderedSpecificPermission" className="form-label">
                            {translate('iamdentityApp.orderedSpecificPermission.returnedBusinessValidityEnd')}
                          </label>
                          <div className="position-relative">
                            <DatePicker
                              popperClassName="datepicker-calendar-popper"
                              portalId="root-portal"
                              selected={value ? new Date(value) : new Date()}
                              onChange={date => {
                                onChange(date);
                              }}
                              value={
                                value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')
                              }
                              onBlur={onBlur}
                              locale={locale === 'ro' ? ro : 'en'}
                              todayButton={<Translate contentKey="global.today">Today</Translate>}
                              showMonthDropdown
                              showYearDropdown
                              timeInputLabel={timeReplaceFinal + ':'}
                              showTimeInput
                              className="form-control"
                              ref={ref}
                              id="user-permission-returnedBusinessValidityEndOrderedSpecificPermission"
                              data-cy="returnedBusinessValidityEndOrderedSpecificPermission"
                            />
                            <span
                              onClick={() => {
                                onChange(null);
                              }}
                              className="fw-semibold close-icon-custom"
                            >
                              {value ? 'X' : ''}
                            </span>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Row>
              </Container>
            </React.Fragment>
          ) : null}

          {task.toa?.orderedUserIdentity ? (
            <React.Fragment>
              <h5>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.home.title">Ordered User Identity</Translate>
              </h5>
              <Container className="container">
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.userName')}:<br />
                    {task?.toa?.orderedUserIdentity?.userName ? task.toa.orderedUserIdentity.userName : null}
                  </div>
                  <div>
                    <span className="pointer" onClick={() => copyValueToInput('returnedUserName', task.toa.orderedUserIdentity.userName)}>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      required
                      label={`*${translate('iamdentityApp.orderedUserIdentity.returnedUserName')}`}
                      name="returnedUserName"
                      type="text"
                    />{' '}
                  </div>
                </Row>

                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.alternativeUserName')}:<br />
                    {task?.toa?.orderedUserIdentity?.alternativeUserName ? task.toa.orderedUserIdentity.alternativeUserName : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() => copyValueToInput('returnedAlternativeUserName', task.toa.orderedUserIdentity.alternativeUserName)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedAlternativeUserName')}
                      name="returnedAlternativeUserName"
                      type="text"
                    />{' '}
                  </div>
                </Row>

                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.associatedEmailAddress')}:<br />
                    {task?.toa?.orderedUserIdentity?.associatedEmailAddress ? task.toa.orderedUserIdentity.associatedEmailAddress : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput('returnedAssociatedEmailAddress', task.toa.orderedUserIdentity.associatedEmailAddress)
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    {' '}
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedAssociatedEmailAddress')}
                      name="returnedAssociatedEmailAddress"
                      type="email"
                    />
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.displayName')}:<br />
                    {task?.toa?.orderedUserIdentity?.displayName ? task.toa.orderedUserIdentity.displayName : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() => copyValueToInput('returnedDisplayName', task.toa.orderedUserIdentity.displayName)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    {' '}
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedDisplayName')}
                      name="returnedDisplayName"
                      type="text"
                    />
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.initialPassword')}:<br />
                    *****
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() => copyValueToInput('returnedInitialPassword', task.toa.orderedUserIdentity.initialPassword)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedInitialPassword')}
                      name="returnedInitialPassword"
                      type="password"
                    />{' '}
                  </div>
                </Row>

                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.mustChangePasswordOnFirstLogin')}:<br />
                    {task?.toa?.orderedUserIdentity?.mustChangePasswordOnFirstLogin
                      ? task.toa.orderedUserIdentity.mustChangePasswordOnFirstLogin
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedMustChangePasswordOnFirstLogin',
                          task.toa.orderedUserIdentity.mustChangePasswordOnFirstLogin
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedMustChangePasswordOnFirstLogin')}
                      name="returnedMustChangePasswordOnFirstLogin"
                      type="checkbox"
                    />{' '}
                  </div>
                </Row>

                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.dateNextChangePassword')}:<br />
                    {task?.toa?.orderedUserIdentity?.dateNextChangePassword
                      ? moment(task.toa.orderedUserIdentity.dateNextChangePassword).format(convertDateFormat(locale, 'date'))
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        task?.toa?.orderedUserIdentity?.dateNextChangePassword
                          ? copyValueToInput('returnedDateNextChangePassword', task.toa.orderedUserIdentity.dateNextChangePassword)
                          : null
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div className="date-picker-container mb-3">
                    <Controller
                      control={control}
                      name="returnedDateNextChangePassword"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div className="form-group">
                          <label htmlFor="user-identity-returnedDateNextChangePassword" className="form-label">
                            {translate('iamdentityApp.orderedUserIdentity.returnedDateNextChangePassword')}
                          </label>
                          <div className="position-relative">
                            <DatePicker
                              selected={value ? new Date(value) : new Date()}
                              onChange={date => {
                                onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                              }}
                              value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                              onBlur={onBlur}
                              locale={locale === 'ro' ? ro : 'en'}
                              todayButton={<Translate contentKey="global.today">Today</Translate>}
                              showMonthDropdown
                              showYearDropdown
                              className="form-control"
                              ref={ref}
                              id="user-identity-returnedDateNextChangePassword"
                              data-cy="returnedDateNextChangePassword"
                            />
                            <span
                              onClick={() => {
                                onChange(null);
                              }}
                              className={`fw-semibold close-icon-custom`}
                            >
                              {value ? 'X' : ''}
                            </span>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.businessValidityStart')}:<br />
                    {task?.toa?.orderedUserIdentity?.businessValidityStart
                      ? moment(task.toa.orderedUserIdentity.businessValidityStart).format(convertDateFormat(locale, 'instant'))
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityStartOrderedUserIdentity',
                          task?.toa?.orderedUserIdentity?.businessValidityStart
                            ? moment(task.toa.orderedUserIdentity.businessValidityStart)
                                .format(convertDateFormat('en', 'instant'))
                                .toString()
                            : null
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div className="date-picker-container date-picker-custom-content mb-3">
                    <Controller
                      control={control}
                      name="returnedBusinessValidityStartOrderedUserIdentity"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div className="form-group">
                          <label htmlFor="user-identity-returnedBusinessValidityStartOrderedUserIdentity" className="form-label">
                            {translate('iamdentityApp.orderedUserIdentity.returnedBusinessValidityStart')}
                          </label>
                          <div className="position-relative">
                            <DatePicker
                              popperClassName="datepicker-calendar-popper"
                              portalId="root-portal"
                              selected={value ? new Date(value) : new Date()}
                              onChange={date => {
                                onChange(date);
                              }}
                              value={
                                value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')
                              }
                              onBlur={onBlur}
                              locale={locale === 'ro' ? ro : 'en'}
                              todayButton={<Translate contentKey="global.today">Today</Translate>}
                              showMonthDropdown
                              showYearDropdown
                              timeInputLabel={timeReplaceFinal + ':'}
                              showTimeInput
                              className="form-control"
                              ref={ref}
                              id="user-identity-returnedBusinessValidityStartOrderedUserIdentity"
                              data-cy="returnedBusinessValidityStartOrderedUserIdentity"
                            />
                            <span
                              onClick={() => {
                                onChange(null);
                              }}
                              className="fw-semibold close-icon-custom"
                            >
                              {value ? 'X' : ''}
                            </span>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.businessValidityEnd')}:<br />
                    {task?.toa?.orderedUserIdentity?.businessValidityEnd
                      ? moment(task.toa.orderedUserIdentity.businessValidityEnd).format(convertDateFormat(locale, 'instant'))
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityEndOrderedUserIdentity',
                          task?.toa?.orderedUserIdentity?.businessValidityEnd
                            ? moment(task.toa.orderedUserIdentity.businessValidityEnd).format(convertDateFormat('en', 'instant')).toString()
                            : null
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div className="date-picker-container date-picker-custom-content mb-3">
                    <Controller
                      control={control}
                      name="returnedBusinessValidityEndOrderedUserIdentity"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div className="form-group">
                          <label htmlFor="user-identity-returnedBusinessValidityEndOrderedUserIdentity" className="form-label">
                            {translate('iamdentityApp.orderedUserIdentity.returnedBusinessValidityEnd')}
                          </label>
                          <div className="position-relative">
                            <DatePicker
                              popperClassName="datepicker-calendar-popper"
                              portalId="root-portal"
                              selected={value ? new Date(value) : new Date()}
                              onChange={date => {
                                onChange(date);
                              }}
                              value={
                                value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')
                              }
                              onBlur={onBlur}
                              locale={locale === 'ro' ? ro : 'en'}
                              todayButton={<Translate contentKey="global.today">Today</Translate>}
                              showMonthDropdown
                              showYearDropdown
                              timeInputLabel={timeReplaceFinal + ':'}
                              showTimeInput
                              className="form-control"
                              ref={ref}
                              id="user-identity-returnedBusinessValidityEndOrderedUserIdentity"
                              data-cy="returnedBusinessValidityEndOrderedUserIdentity"
                            />
                            <span
                              onClick={() => {
                                onChange(null);
                              }}
                              className="fw-semibold close-icon-custom"
                            >
                              {value ? 'X' : ''}
                            </span>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Row>
              </Container>
            </React.Fragment>
          ) : null}

          <Container>
            <Row className={task.toa?.orderedUserIdentity ? ' user-identity' : ' rest'}>
              <div> </div>
              <div> </div>
              <div className="executionResultForceWidth">
                <ValidatedInput
                  control={control}
                  label={`${translate('iamdentityApp.tasks.executionResult')}`}
                  name="executionResult"
                  type="text"
                />{' '}
              </div>
            </Row>
          </Container>
          <Container>
            {formData?.groups?.map(group => {
              if (group.name === FormBusinessGroup.ConnectorCommandAttributeDTO && group.values) {
                return (
                  <React.Fragment key={group.name}>
                    <div>
                      <div> </div>
                      <div> </div>
                      <h5>{group.name}</h5>
                      {group?.values?.map(value => {
                        const decimalNumberInputType = ['float', 'number'];
                        return (
                          <ValidatedInput
                            key={value.name}
                            control={control}
                            label={value.name}
                            name={value.name}
                            type={changeTypestoInput(value.valueType)}
                            step={decimalNumberInputType.includes(value.valueType) ? '.01' : null}
                          />
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              }
            })}
          </Container>
          <div className="d-flex justify-content-end">
            <Button color="success" id="save-entity" disabled={updateSuccess} data-cy="entityCreateSaveButton" type="submit">
              <FontAwesomeIcon icon="save" /> {translate('iamdentityApp.tasks.updateCompleteManual')}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCard;
