import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { Button, Card, Col, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import ExpressionBuilder from 'app/shared/components/expression-builder/expression-builder';
import { IExpressionEntity } from 'app/shared/model/expression-entity.model';
import { IRiskRule } from 'app/shared/model/risk-rule.model';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { createEntity, getEntity, resetEntity, updateEntity } from './risk-rule.reducer';

import { ENTITY_NAME } from 'app/shared/model/risk-rule.model';
import { ValueType } from 'app/shared/components/expression-builder/types';

export const RiskRuleUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [riskConditionExpression, setRiskConditionExpression] = useState(null);
  const [riskResultExpression, setRiskResultExpression] = useState(null);

  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const riskRuleEntity: IRiskRule = useAppSelector(state => state.riskRule.entity);
  const loading = useAppSelector(state => state.riskRule.loading);
  const updating = useAppSelector(state => state.riskRule.updating);
  const updateSuccess = useAppSelector(state => state.riskRule.updateSuccess);
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    setRiskConditionExpression(riskRuleEntity.riskConditionExpression);
    setRiskResultExpression(riskRuleEntity.riskResultExpression);
    formReset(defaultValues());
  }, [riskRuleEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...riskRuleEntity,
      ...values,
      riskConditionExpression: riskConditionExpression,
      riskResultExpression: riskResultExpression,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...riskRuleEntity,
        };
  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });
  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.riskRule.home.createOrEditLabel" data-cy="RiskRuleCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.riskRule.home.createOrEditLabel">Create or edit a RiskRule</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="risk-rule-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={'*' + translate('iamdentityApp.riskRule.name')}
                id="risk-rule-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedField
                label={translate('iamdentityApp.riskRule.description')}
                id="risk-rule-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <FormGroup key={`riskConditionExpression-${id}`}>
                <Label>
                  <Translate contentKey="iamdentityApp.riskRule.riskConditionExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={riskConditionExpression}
                  restrictedValueType={ValueType.BOOLEAN}
                  onChange={expression => setRiskConditionExpression(expression)}
                />
              </FormGroup>
              {/* <FormGroup key={`riskResultExpression-${id}`}>
                <Label>
                  <Translate contentKey="iamdentityApp.riskRule.riskResultExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={riskResultExpression}
                  restrictedValueType={ValueType.NUMBER}
                  onChange={expression => setRiskResultExpression(expression)}
                />
              </FormGroup> */}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default RiskRuleUpdate;
