import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { apiUrl as roleGroupUrl } from 'app/entities/role-group/role-group.reducer';
import { apiUrl as userIdentityUrl } from 'app/entities/user-identity/user-identity.reducer';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IUserGroup } from 'app/shared/model/user-group.model';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import moment from 'moment';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity } from './user-group.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { apiUrl as ordersUrl } from 'app/entities/orders/orders.reducer';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';

interface ISearchParamsObject {
  order?: { id: number };
}

export const UserGroupUpdate = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const userGroupEntity: IUserGroup = useAppSelector(state => state.userGroup.entity);
  const loading = useAppSelector(state => state.userGroup.loading);
  const updating = useAppSelector(state => state.userGroup.updating);
  const updateSuccess = useAppSelector(state => state.userGroup.updateSuccess);
  const permissionGrantLevelValues = Object.keys(PermissionGrantLevel);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.USER_GROUP));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [userGroupEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);
    const entity = {
      ...userGroupEntity,
      ...convertReactSelectValuesToEntityIds(values),
      ...addCustomPropertiesToCustomAttributesMap(userGroupEntity, convertReactSelectValuesToEntityIds(values), customAttributeDefinitions),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = (): any => {
    const result = isNew
      ? { startDate: displayDefaultDateTime(), endDate: displayDefaultDateTime(), ...searchParamsObject }
      : {
          ...userGroupEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, userGroupEntity),
          startDate: convertDateTimeFromServer(userGroupEntity.startDate),
          endDate: convertDateTimeFromServer(userGroupEntity.endDate),
          userIdentity: userGroupEntity?.userIdentity,
          roleGroup: userGroupEntity?.roleGroup,
          lastModifiedByOrder: userGroupEntity?.lastModifiedByOrder,
          ...searchParamsObject,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.userGroup.home.createOrEditLabel" data-cy="UserGroupCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.userGroup.home.createOrEditLabel">Create or edit a UserGroup</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="user-group-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.userGroup.startDate')}
                id="user-group-startDate"
                name="startDate"
                data-cy="startDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                onChange={event => (startDate.current = event.target.value)}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.userGroup.endDate')}
                id="user-group-endDate"
                name="endDate"
                data-cy="endDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                min={startDate.current}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.userGroup.grantedBy')}
                id="user-group-grantedBy"
                name="grantedBy"
                data-cy="grantedBy"
                type="select"
              >
                {permissionGrantLevelValues.map(permissionGrantLevel => (
                  <option value={permissionGrantLevel} key={permissionGrantLevel}>
                    {translate('iamdentityApp.PermissionGrantLevel.' + permissionGrantLevel)}
                  </option>
                ))}
              </ValidatedField>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.userGroup.userIdentity" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="userIdentity"
                  url={userIdentityUrl}
                  queryProps={{
                    name: 'userName',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.userGroup.roleGroup" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="roleGroup"
                  url={roleGroupUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.userGroup.lastModifiedByOrder" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="lastModifiedByOrder"
                  url={ordersUrl}
                  isDisabled={!!searchParamsObject?.order}
                  queryProps={{
                    name: 'id',
                    type: 'string',
                  }}
                />
              </FormGroup>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  control: control,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `user-identity-role-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default UserGroupUpdate;
