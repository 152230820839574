import React from 'react';
import { translate } from 'react-jhipster';

export interface ICustomText {
  maxChars?: number;
  hoverText?: string;
  children: React.ReactNode | any;
}

export default function CustomText({ maxChars, hoverText, children }: ICustomText) {
  if (children?.props) children = translate(children.props.contentKey);
  if (Array.isArray(children)) children = children.join(' ');
  if (children == null) children = '';

  return <span title={hoverText ?? children}>{children?.length > maxChars ? `${children.substring(0, maxChars)}..` : children}</span>;
}
