import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Row, Col, Spinner } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IRiskRule } from 'app/shared/model/risk-rule.model';
import { fetchEntityList as getRiskRules } from 'app/entities/risk-rule/risk-rule.reducer';
import { IRiskCombination } from 'app/shared/model/risk-combination.model';
import { RiskLevel } from 'app/shared/model/enumerations/risk-level.model';
import { getEntity, updateEntity, createEntity, reset } from './risk-combination.reducer';
import moment from 'moment';
import { useRef } from 'react';
import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';

export const RiskCombinationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const riskRules = useAppSelector(state => state.riskRule.entities);
  const riskCombinationEntity = useAppSelector(state => state.riskCombination.entity);
  const loading = useAppSelector(state => state.riskCombination.loading);
  const updating = useAppSelector(state => state.riskCombination.updating);
  const updateSuccess = useAppSelector(state => state.riskCombination.updateSuccess);
  const riskLevelValues = Object.keys(RiskLevel);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getRiskRules({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...riskCombinationEntity,
      ...values,
      firstRule: riskRules.find(it => it.id.toString() === values.firstRule.toString()),
      secondRule: riskRules.find(it => it.id.toString() === values.secondRule.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          riskLevel: 'LOW',
          ...riskCombinationEntity,
          firstRule: riskCombinationEntity?.firstRule?.id,
          secondRule: riskCombinationEntity?.secondRule?.id,
        };

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.riskCombination.home.createOrEditLabel" data-cy="RiskCombinationCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.riskCombination.home.createOrEditLabel">Create or edit a RiskCombination</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="risk-combination-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={'*' + translate('iamdentityApp.riskCombination.name')}
                id="risk-combination-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedField
                label={translate('iamdentityApp.riskCombination.description')}
                id="risk-combination-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.riskCombination.riskLevel')}
                id="risk-combination-riskLevel"
                name="riskLevel"
                data-cy="riskLevel"
                type="select"
              >
                {riskLevelValues.map(riskLevel => (
                  <option value={riskLevel} key={riskLevel}>
                    {translate('iamdentityApp.RiskLevel.' + riskLevel)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                id="risk-combination-firstRule"
                name="firstRule"
                data-cy="firstRule"
                label={translate('iamdentityApp.riskCombination.firstRule')}
                type="select"
              >
                <option value="" key="0" />
                {riskRules
                  ? riskRules.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="risk-combination-secondRule"
                name="secondRule"
                data-cy="secondRule"
                label={translate('iamdentityApp.riskCombination.secondRule')}
                type="select"
              >
                <option value="" key="0" />
                {riskRules
                  ? riskRules.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default RiskCombinationUpdate;
