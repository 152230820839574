import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, translate, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  findCustomAttributeValueByKey,
  getFiltersForCustomAttributes,
  overridePaginationStateWithQueryParams,
} from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import Filter from 'app/shared/FilterComponent';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { exportEntities, getEntities, searchEntities } from './specific-permission.reducer';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import { ISpecificPermission } from 'app/shared/model/specific-permission.model';
import { UserStatus } from 'app/shared/model/enumerations/user-status.model';
import ShowCustomAttributes from 'app/shared/components/customButtons/showCustomAttributes';
import CustomText from 'app/shared/components/customText/customText';
import { APP_TABLE_TEXT_MAX_CHARS } from 'app/config/constants';

export const SpecificPermission = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const specificPermissionList: ISpecificPermission[] = useAppSelector(state => state.specificPermission.entities);
  const loading = useAppSelector(state => state.specificPermission.loading);
  const totalItems = useAppSelector(state => state.specificPermission.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state =>
    state.customAttributeDefinition.entities?.filter(
      def => def.visibleInList && localStorage.getItem('TABLE_showCustomAttributes') === 'true'
    )
  );

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.SPECIFIC_PERMISSION));
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="specific-permission-heading" data-cy="SpecificPermissionHeading">
        <Translate contentKey="iamdentityApp.specificPermission.home.title">Specific Permissions</Translate>
        <div className="d-flex justify-content-end">
          <ShowCustomAttributes />
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} className={'me-1'} />
            <Translate contentKey="iamdentityApp.specificPermission.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          {/* Comentat deoarece este posibil sa revenim la acest buton de "create" */}
          {/* <Link
            to="/specific-permission/new"
            className="btn btn-success jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            <Translate contentKey="iamdentityApp.specificPermission.home.createLabel">Create new Specific Permission</Translate>
          </Link> */}
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom h-100">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'string',
                    placeholder: translate('iamdentityApp.specificPermission.id'),
                  },
                  {
                    name: 'employee',
                    type: 'string',
                    placeholder: translate('iamdentityApp.specificPermission.employee'),
                  },
                  {
                    name: 'application',
                    type: 'string',
                    placeholder: translate('iamdentityApp.specificPermission.application'),
                  },
                  {
                    name: 'userIdentity',
                    type: 'string',
                    placeholder: translate('iamdentityApp.specificPermission.userIdentity'),
                  },
                  {
                    name: 'permissionDefinition',
                    type: 'string',
                    placeholder: translate('iamdentityApp.specificPermission.permissionDefinition'),
                  },
                  {
                    name: 'status',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(UserStatus).map(value => ({
                        value,
                        label: translate(`iamdentityApp.UserStatus.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'startDate',
                    type: 'date_as_instant',
                  },
                  {
                    name: 'endDate',
                    type: 'date_as_instant',
                  },
                  {
                    name: 'grantedBy',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(PermissionGrantLevel).map(value => ({
                        value,
                        label: translate(`iamdentityApp.PermissionGrantLevel.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'explicitlyRemoved',
                    type: 'boolean',
                  },
                  {
                    name: 'lastModifiedByOrderId',
                    type: 'number',
                    placeholder: translate('iamdentityApp.specificPermission.lastModifiedByOrder'),
                  },
                  ...getFiltersForCustomAttributes(customAttributeDefinitions),
                ]}
              />
            )}
            <tr>
              <th className="hand id-size" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('userIdentity.employee.code')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.employee">employee</Translate>
                  </CustomText>
                  {handleSortIconsTable('userIdentity.employee.code')}
                </div>
              </th>
              <th className="hand" onClick={sort('userIdentity.application.name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.application">application</Translate>
                  </CustomText>
                  {handleSortIconsTable('userIdentity.application.name')}
                </div>
              </th>
              <th className="hand" onClick={sort('userIdentity')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.user">User</Translate>
                  </CustomText>
                  {handleSortIconsTable('userIdentity')}
                </div>
              </th>
              <th className="hand" onClick={sort('permissionDefinition.name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.permissionDefinition">Permission Definition</Translate>
                  </CustomText>
                  {handleSortIconsTable('permissionDefinition.name')}
                </div>
              </th>
              <th className="hand" onClick={sort('status')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.status">Status</Translate>
                  </CustomText>
                  {handleSortIconsTable('status')}
                </div>
              </th>
              <th className="hand" onClick={sort('startDate')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.startDate">Start Date</Translate>
                  </CustomText>
                  {handleSortIconsTable('startDate')}
                </div>
              </th>
              <th className="hand" onClick={sort('endDate')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.endDate">End Date</Translate>
                  </CustomText>
                  {handleSortIconsTable('endDate')}
                </div>
              </th>
              <th className="hand" onClick={sort('grantedBy')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.grantedBy">Granted By</Translate>
                  </CustomText>
                  {handleSortIconsTable('grantedBy')}
                </div>
              </th>
              <th className="hand" onClick={sort('explicitlyRemoved')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.explicitlyRemoved">Explicitly Removed</Translate>
                  </CustomText>
                  <FontAwesomeIcon icon="sort" />
                </div>
              </th>
              <th className="hand" onClick={sort('lastModifiedByOrder')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.specificPermission.lastModifiedByOrder">Last Modified By Order</Translate>
                  </CustomText>
                  {handleSortIconsTable('lastModifiedByOrder')}
                </div>
              </th>
              {customAttributeDefinitions?.map(cad => (
                <th key={cad.id} className="hand">
                  {cad.displayName}
                </th>
              ))}
              <th className="hand id-size">
                {/*  <span>*/}
                {/*    <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'}*/}
                {/*            onClick={toggleFilter}>*/}
                {/*      <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*    </Button>*/}
                {/*  </span>*/}
                {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide*/}
                {/*    Filter</Translate>*/}
                {/*</UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {specificPermissionList.map((specificPermission, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Link to={`/specific-permission/${specificPermission.id}`} color="link">
                    {specificPermission.id}
                  </Link>
                </td>
                <td>
                  {specificPermission?.userIdentity?.employee ? (
                    <Link to={`/employee/${specificPermission.userIdentity.employee.id}`}>
                      <CustomText>
                        {specificPermission.userIdentity.employee.fullName
                          ? specificPermission.userIdentity.employee.code + ' - ' + specificPermission.userIdentity.employee.fullName
                          : specificPermission.userIdentity.employee.code}
                      </CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {specificPermission?.userIdentity?.application ? (
                    <Link to={`/application/${specificPermission.userIdentity.application.id}`}>
                      <CustomText>{specificPermission.userIdentity.application.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {specificPermission.userIdentity ? (
                    <Link to={`/user-identity/${specificPermission.userIdentity.id}`}>
                      <CustomText>{specificPermission.userIdentity.userName}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {specificPermission.permissionDefinition ? (
                    <Link to={`/permission-definition/${specificPermission.permissionDefinition.id}`}>
                      <CustomText>{specificPermission.permissionDefinition.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.UserStatus.${specificPermission.status}`)}</CustomText>
                </td>
                <td>
                  {specificPermission.startDate ? (
                    <TextFormat type="date" value={specificPermission.startDate} format={convertDateFormat(locale, 'date')} />
                  ) : null}
                </td>
                <td>
                  {specificPermission.endDate ? (
                    <TextFormat type="date" value={specificPermission.endDate} format={convertDateFormat(locale, 'date')} />
                  ) : null}
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.PermissionGrantLevel.${specificPermission.grantedBy}`)}</CustomText>
                </td>
                <td>
                  {specificPermission.explicitlyRemoved === null || specificPermission.explicitlyRemoved === undefined
                    ? ''
                    : specificPermission.explicitlyRemoved === true
                    ? translate('global.yes')
                    : translate('global.no')}
                </td>
                <td>
                  {specificPermission.lastModifiedByOrder ? (
                    <Link to={`/orders/${specificPermission.lastModifiedByOrder.id}`}>
                      <CustomText>{specificPermission.lastModifiedByOrder.id}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                {customAttributeDefinitions?.map(cad => (
                  <td key={cad.id}>
                    <CustomText>{findCustomAttributeValueByKey(cad, specificPermission, locale)}</CustomText>
                  </td>
                ))}
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={`/specific-permission/${specificPermission.id}`}
                      color="info"
                      size="sm"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" />
                      {/*<span className="d-none d-md-inline">*/}
                      {/*    <Translate contentKey="entity.action.view">View</Translate>*/}
                      {/*  </span>*/}
                    </Button>
                    {/* Comentat deoarece este posibil sa revenim la aceste butoane de "edit" si "delete" */}
                    {/* <Button
                      tag={Link}
                      to={`/specific-permission/${specificPermission.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="warning"
                      size="sm"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.edit">Edit</Translate>
                      </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`/specific-permission/${specificPermission.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash" />
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                      </span>
                    </Button> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!specificPermissionList || specificPermissionList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.specificPermission.home.notFound">No Specific Permissions found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={specificPermissionList && specificPermissionList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};

export default SpecificPermission;
