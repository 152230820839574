import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { apiUrl as orderedUserIdentityUrl } from 'app/entities/ordered-user-identity/ordered-user-identity.reducer';
import { apiUrl as ordersUrl } from 'app/entities/orders/orders.reducer';
import { apiUrl as roleDefinitionUrl } from 'app/entities/role-definition/role-definition.reducer';
import { apiUrl as userIdentityUrl } from 'app/entities/user-identity/user-identity.reducer';
import { apiUrl as applicationUrl } from 'app/entities/application/application.reducer';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { OperationType } from 'app/shared/model/enumerations/operation-type.model';
import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IOrderedRole } from 'app/shared/model/ordered-role.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, resetEntity, updateEntity } from './ordered-role.reducer';
import { IOrder } from 'app/shared/model/order.model';
import { AppliedOnTypes } from 'app/shared/model/enumerations/applied-on-types.model';
import { ModificationLevelType } from 'app/shared/model/enumerations/modification-level-type.model';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import moment from 'moment';
import { APP_GLOBAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';

const APPLICATION_LIST_URL = 'api/order-create/application-list';

export const OrderedRoleUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  //orders => view => requested roles
  const checkRequestedRole = location.pathname.includes('requested-roles');

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState(buildSearchParamsObject(searchParams));

  const { orderedRoleId: id } = useParams<'orderedRoleId'>();
  const isNew = id === undefined;

  const orderedRoleEntity: IOrderedRole = useAppSelector(state => state.orderedRole.entity);
  const orderEntity: IOrder = useAppSelector(state => state.orders.entity);
  const loading = useAppSelector(state => state.orderedRole.loading);
  const updating = useAppSelector(state => state.orderedRole.updating);
  const updateSuccess = useAppSelector(state => state.orderedRole.updateSuccess);
  const permissionGrantLevelValues = Object.keys(PermissionGrantLevel);
  const orderExecutionStatusValues = Object.keys(OrderExecutionStatus);
  const operationTypeValues = Object.keys(OperationType);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORDERED_ROLE));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [orderedRoleEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    values.scheduleTime = convertDateTimeToServer(values.scheduleTime);
    values.businessValidityStart = convertDateTimeToServer(values.businessValidityStart);
    values.businessValidityEnd = convertDateTimeToServer(values.businessValidityEnd);

    const entity = {
      ...orderedRoleEntity,
      ...addCustomPropertiesToCustomAttributesMap(
        orderedRoleEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
      order: { id: orderEntity?.id },
    };
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const filterApplication = (
    employeeId?: number,
    scheduleTime: any = '',
    forStandardPolicy?: boolean,
    temporary = false,
    orderTypeId?: number
  ) =>
    APPLICATION_LIST_URL +
    '?employeeId=' +
    employeeId +
    '&scheduleTime=' +
    scheduleTime +
    '&forStandardPolicy=' +
    forStandardPolicy +
    '&temporary=' +
    temporary +
    '&appliedOn=' +
    AppliedOnTypes.ROLE +
    '&modificationLevelType=' +
    ModificationLevelType.ADD_NEW +
    '&orderTypeId=' +
    orderTypeId;

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = () =>
    isNew
      ? {
          ...searchParamsObject,
          scheduleTime: searchParamsObject?.scheduleTime
            ? moment(searchParamsObject.scheduleTime).format(APP_GLOBAL_DATETIME_FORMAT)
            : null,
          businessValidityStart: searchParamsObject?.businessValidityStart
            ? moment(searchParamsObject.businessValidityStart).format(APP_GLOBAL_DATETIME_FORMAT)
            : displayDefaultDateTime(),
          businessValidityEnd: searchParamsObject?.businessValidityEnd
            ? moment(searchParamsObject.businessValidityEnd).format(APP_GLOBAL_DATETIME_FORMAT)
            : null,
        }
      : {
          ...orderedRoleEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, orderedRoleEntity),
          grantedBy: orderedRoleEntity.grantedBy,
          scheduleTime: convertDateTimeFromServer(orderedRoleEntity.scheduleTime),
          businessValidityStart: convertDateTimeFromServer(orderedRoleEntity.businessValidityStart),
          businessValidityEnd: convertDateTimeFromServer(orderedRoleEntity.businessValidityEnd),
          order: orderedRoleEntity?.order,
          userIdentity: orderedRoleEntity?.userIdentity,
          orderedUserIdentity: orderedRoleEntity?.orderedUserIdentity,
          roleDefinition: orderedRoleEntity?.roleDefinition,
          dependsOn: orderedRoleEntity?.dependsOn,
          operationType: orderedRoleEntity?.operationType,
          application: orderedRoleEntity?.application,
        };
  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
    setValue,
    resetField,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchApplication = watch('application');
  const watchOrderedUserIdentity = watch('orderedUserIdentity');
  const watchUserIdentity = watch('userIdentity');
  const watchBusinessValidityStart = watch('businessValidityStart');
  const watchBusinessValidityEnd = watch('businessValidityEnd');

  useEffect(() => {
    if (watchApplication && !searchParamsObject) {
      resetField('orderedUserIdentity');
      setValue('orderedUserIdentity', null);
      resetField('userIdentity');
      setValue('userIdentity', null);
      resetField('permissionDefinition');
      setValue('permissionDefinition', null);
    }
  }, [watchApplication]);

  useEffect(() => {
    if (isNew) {
      setValue('executionStatus', OrderExecutionStatus.NOT_STARTED);
    }

    if ((watchOrderedUserIdentity || watchUserIdentity) && !searchParamsObject) {
      resetField('permissionDefinition');
      setValue('permissionDefinition', null);
    }
  }, []);

  useEffect(() => {
    if (watchBusinessValidityEnd && Object.keys(searchParamsObject).length === 0) {
      setValue('grantedBy', PermissionGrantLevel.BY_TEMPORARY_REQUEST);
    }
  }, [watchBusinessValidityEnd]);

  return (
    <Card className="jh-card pad-1">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.orderedRole.home.createOrEditLabel" data-cy="OrderedRoleCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.orderedRole.home.createOrEditLabel">Create or edit a OrderedRole</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="ordered-role-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedRole.description')}
                id="ordered-role-description"
                name="description"
                data-cy="description"
                type="text"
              />
              {!checkRequestedRole && (
                <React.Fragment>
                  <ValidatedField
                    register={register}
                    label={translate('iamdentityApp.orderedRole.grantedBy')}
                    id="ordered-role-grantedBy"
                    name="grantedBy"
                    data-cy="grantedBy"
                    type="select"
                    disabled={searchParamsObject?.grantedBy ? true : false}
                  >
                    {permissionGrantLevelValues.map(permissionGrantLevel => (
                      <option value={permissionGrantLevel} key={permissionGrantLevel}>
                        {translate('iamdentityApp.PermissionGrantLevel.' + permissionGrantLevel)}
                      </option>
                    ))}
                  </ValidatedField>
                  <ValidatedField
                    register={register}
                    label={translate('iamdentityApp.orderedRole.executionStatus')}
                    id="ordered-role-executionStatus"
                    name="executionStatus"
                    data-cy="executionStatus"
                    type="select"
                    disabled={searchParamsObject?.executionStatus ? true : false}
                  >
                    {orderExecutionStatusValues.map(orderExecutionStatus => (
                      <option value={orderExecutionStatus} key={orderExecutionStatus}>
                        {translate('iamdentityApp.OrderExecutionStatus.' + orderExecutionStatus)}
                      </option>
                    ))}
                  </ValidatedField>
                  <ValidatedField
                    register={register}
                    label={translate('iamdentityApp.orderedRole.executionResult')}
                    id="ordered-role-executionResult"
                    name="executionResult"
                    data-cy="executionResult"
                    type="text"
                  />
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.orderedRole.orders" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="order"
                      url={ordersUrl}
                      isDisabled={searchParamsObject?.order ? true : false}
                      queryProps={{
                        name: 'id',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                  <ValidatedField
                    register={register}
                    label={translate('iamdentityApp.orderedRole.operationType')}
                    id="ordered-role-operationType"
                    name="operationType"
                    data-cy="operationType"
                    type="select"
                    disabled={searchParamsObject?.operationType || !isNew ? true : false}
                  >
                    {operationTypeValues.map(operationType => (
                      <option value={operationType} key={operationType}>
                        {translate('iamdentityApp.OperationType.' + operationType)}
                      </option>
                    ))}
                  </ValidatedField>
                  <ValidatedField
                    register={register}
                    label={translate('iamdentityApp.orderedRole.scheduleTime')}
                    id="ordered-role-scheduleTime"
                    name="scheduleTime"
                    data-cy="scheduleTime"
                    disabled={searchParamsObject?.scheduleTime ? true : false}
                    type="datetime-local"
                    placeholder="YYYY-MM-DD HH:mm"
                  />
                </React.Fragment>
              )}

              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedRole.businessValidityStart')}
                id="ordered-role-businessValidityStart"
                name="businessValidityStart"
                data-cy="businessValidityStart"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                min={
                  searchParamsObject?.businessValidityStart
                    ? moment(searchParamsObject.businessValidityStart).format(APP_LOCAL_DATETIME_FORMAT)
                    : displayDefaultDateTime()
                }
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedRole.businessValidityEnd')}
                id="ordered-role-businessValidityEnd"
                name="businessValidityEnd"
                data-cy="businessValidityEnd"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                min={watchBusinessValidityStart}
              />

              <FormGroup>
                <Label>
                  *<Translate contentKey="iamdentityApp.application.detail.title" />
                </Label>
                <PaginatedReactSelect
                  validate={{ required: true }}
                  control={control}
                  name="application"
                  isPageable={checkRequestedRole ? false : true}
                  isDisabled={searchParamsObject?.application || !isNew ? true : false}
                  url={
                    checkRequestedRole
                      ? filterApplication(orderEntity?.employee?.id, '', false, orderEntity.temporary, orderEntity?.orderType?.id)
                      : applicationUrl
                  }
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              {watchApplication && (
                <React.Fragment>
                  {!watchUserIdentity && (
                    <FormGroup>
                      <Label>
                        <Translate contentKey="iamdentityApp.orderedRole.orderedUserIdentity" />
                      </Label>
                      <PaginatedReactSelect
                        control={control}
                        name="orderedUserIdentity"
                        url={orderedUserIdentityUrl}
                        isDisabled={searchParamsObject?.orderedUserIdentity || !isNew ? true : false}
                        queryProps={{
                          name: 'userName',
                          type: 'string',
                          predefinedQuery: `applicationId.equals=${watchApplication.id}&orderId.equals=${orderEntity?.id}`,
                        }}
                      />
                    </FormGroup>
                  )}
                  {!watchOrderedUserIdentity && (
                    <FormGroup>
                      <Label>
                        <Translate contentKey="iamdentityApp.orderedRole.userIdentity" />
                      </Label>
                      <PaginatedReactSelect
                        control={control}
                        name="userIdentity"
                        url={userIdentityUrl}
                        queryProps={{
                          name: 'userName',
                          type: 'string',
                          predefinedQuery: `applicationId.equals=${watchApplication.id}`,
                        }}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label>
                      *<Translate contentKey="iamdentityApp.orderedRole.roleDefinition" />
                    </Label>
                    <PaginatedReactSelect
                      validate={{ required: true }}
                      control={control}
                      name="roleDefinition"
                      url={roleDefinitionUrl}
                      queryProps={{
                        name: 'name',
                        type: 'string',
                        predefinedQuery: `applicationId.equals=${watchApplication.id}`,
                      }}
                    />
                  </FormGroup>
                </React.Fragment>
              )}

              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  control: control,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `orderedRole-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default OrderedRoleUpdate;
