import React, { useEffect } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { FormGroup, Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';

interface IValidatedInputProps {
  validate?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  control: Control;
  name: string;
  label: string;
  type?: InputType;
  isDisabled?: boolean;
  min?: any;
  max?: any;
  required?: boolean;
  step?: string | number;
  className?: string;
  id?: string;
}

function ValidatedInput(props: IValidatedInputProps) {
  return (
    <Controller
      rules={props?.validate}
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value, ref, onBlur }, fieldState: { error, isTouched }, formState }) => {
        return (
          <FormGroup>
            <Label>{props.label}</Label>
            <Input
              value={value}
              onChange={onChange}
              valid={isTouched && !error}
              invalid={!!error}
              type={props?.type ? props.type : 'text'}
              readOnly={!!props?.isDisabled}
              innerRef={ref}
              required={props?.required}
              min={props?.min}
              max={props?.max}
              step={props?.step}
              className={props?.className}
              id={props?.id}
            />
          </FormGroup>
        );
      }}
    />
  );
}

export default ValidatedInput;
