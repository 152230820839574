import { INPUT_ENUM_SEPARATOR } from 'app/config/constants';
import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { ValidatedField, ValidatedFieldProps, Translate, translate } from 'react-jhipster';
import { FormGroup, Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import { CustomAttributeType } from '../model/enumerations/custom-attribute-type.model';
import Select from 'react-select';
import { generateSelectStyles } from './react-select-utils';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import moment from 'moment';

interface IRenderAndRegisterCustomAttribute {
  attributeType: CustomAttributeType;
  name: string;
  label: string;
  register: UseFormRegister<FieldValues>;
  options?: string;
  id?: any;
  control?: any;
  locale?: string;
}

export const renderAndRegisterCustomAttributeField = ({
  attributeType,
  name,
  label,
  register,
  options,
  id,
  control,
  locale,
}: IRenderAndRegisterCustomAttribute) => {
  let type: InputType = 'text';

  switch (attributeType) {
    case CustomAttributeType.ENUMERATION:
      type = 'select';
      break;
    case CustomAttributeType.MULTISELECT:
      type = 'select';
      break;
    case CustomAttributeType.BOOLEAN:
      type = 'select';
      break;
    case CustomAttributeType.DATE:
      type = 'date';
      break;
    case CustomAttributeType.NUMBER:
      type = 'number';
      break;
    case CustomAttributeType.TEXT:
    default:
      type = 'text';
      break;
  }

  const selectOptionsInit = (optionsParam, separator: string) => {
    if (!optionsParam) return [];
    return optionsParam.split(separator).map(option => ({
      value: option.trim(),
      label: option.trim(),
    }));
  };
  const selectOptions = selectOptionsInit(options, INPUT_ENUM_SEPARATOR);
  const defaultStyleParams = {
    error: null,
    isTouched: false,
    isDisabled: false,
  };

  const { ref: inputRef, ...inputProps } = register(name);

  return (
    <React.Fragment>
      {attributeType === CustomAttributeType.MULTISELECT && options ? (
        <FormGroup key={`custom-attribute-${name}`}>
          <Label for={id}>{label}</Label>
          <Controller
            name={name.replaceAll('.', '_#_')}
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value } }) => (
              <Select
                styles={generateSelectStyles(defaultStyleParams)}
                value={
                  Array.isArray(value)
                    ? value.map((val: string) => ({ label: val, value: val })) // Dacă value este array
                    : value
                    ? value.split('|').map((val: string) => ({ label: val, value: val })) // Dacă value este string
                    : []
                }
                isMulti
                options={selectOptions}
                closeMenuOnSelect={false}
                data-cy={name}
                key={name}
                id={id}
                onChange={selectedOptions => {
                  // Transforma array-ul de obiecte selectate într-un string concatenat cu separatorul "|"
                  const selectedValues = selectedOptions.map(option => option.value).join('|');
                  onChange(selectedValues); // Trimite stringul concatenat în formular
                }}
              />
            )}
          />
        </FormGroup>
      ) : attributeType === CustomAttributeType.ENUMERATION ? (
        <FormGroup key={`custom-attribute-${name}`}>
          <Label for={id}>{label}</Label>
          <Controller
            name={name.replaceAll('.', '_#_')}
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value } }) => (
              <Select
                styles={generateSelectStyles(defaultStyleParams)}
                value={value ? { label: value, value } : null}
                options={selectOptions}
                isMulti={false}
                isClearable={true}
                closeMenuOnSelect={true}
                data-cy={name}
                key={name}
                id={id}
                onChange={selectedOption => {
                  const selectedValue = selectedOption ? selectedOption.value : '';
                  onChange(selectedValue);
                }}
              />
            )}
          />
        </FormGroup>
      ) : attributeType === CustomAttributeType.BOOLEAN ? (
        <FormGroup>
          <Label for={id}>{label}</Label>
          <Controller
            name={name.replaceAll('.', '_#_')}
            control={control}
            defaultValue={null}
            render={({ field: { onChange, value } }) => {
              return (
                <select
                  className="form-control"
                  value={value === true ? 'true' : value === false ? 'false' : ''}
                  data-cy={name}
                  key={name}
                  id={id}
                  onChange={event => {
                    const selectedValue = event.target.value === '' ? null : event.target.value === 'true' ? true : false;
                    onChange(selectedValue);
                  }}
                >
                  <option value=""> </option>
                  <option value="true">{translate('global.yes')}</option>
                  <option value="false">{translate('global.no')}</option>
                </select>
              );
            }}
          />
        </FormGroup>
      ) : attributeType === CustomAttributeType.DATE ? (
        <div className="date-picker-container mb-3">
          <Controller
            control={control}
            name={name.replaceAll('.', '_#_')}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="form-group">
                <label htmlFor={id} className="form-label">
                  {label}
                </label>
                <div className="position-relative">
                  <DatePicker
                    selected={value ? new Date(value) : new Date()}
                    onChange={date => {
                      onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                    }}
                    value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                    onBlur={onBlur}
                    locale={locale === 'ro' ? ro : 'en'}
                    todayButton={<Translate contentKey="global.today">Today</Translate>}
                    showMonthDropdown
                    showYearDropdown
                    className="form-control"
                    ref={ref}
                    id={id}
                    data-cy={name}
                  />
                  <span
                    onClick={() => {
                      onChange(null);
                    }}
                    className="fw-semibold close-icon-custom"
                  >
                    {value ? 'X' : ''}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
      ) : (
        <FormGroup key={`custom-attribute-${name}`}>
          <ValidatedField
            key={name}
            register={register}
            label={label}
            id={id}
            name={name.replaceAll('.', '_#_')}
            data-cy={name}
            type={type}
          ></ValidatedField>
        </FormGroup>
      )}
    </React.Fragment>
  );
};

export const renderCustomAttributeField = (
  props: ValidatedFieldProps & {
    attributeType: CustomAttributeType;
    register?: UseFormRegister<any>;
    control?: any;
    options?: string;
    value?: string;
    locale?: string;
  }
): JSX.Element => {
  let type: InputType = 'text';
  const defaultValue = props.value
    ? (props.attributeType === CustomAttributeType.ENUMERATION || props.attributeType === CustomAttributeType.MULTISELECT) &&
      props.value.split('|').length > 1
      ? props.value.split('|').map(val => val)
      : props.value
    : null;

  switch (props.attributeType) {
    case CustomAttributeType.ENUMERATION:
      type = 'select';
      break;
    case CustomAttributeType.MULTISELECT:
      type = 'select';
      break;
    case CustomAttributeType.BOOLEAN:
      type = 'select';
      break;
    case CustomAttributeType.DATE:
      type = 'date';
      break;
    case CustomAttributeType.NUMBER:
      type = 'number';
      break;
    case CustomAttributeType.TEXT:
    default:
      type = 'text';
      break;
  }

  const selectOptionsInit = (optionsParam, separator: string) => {
    if (!optionsParam) return [];
    return optionsParam.split(separator).map(option => ({
      value: option.trim(),
      label: option.trim(),
    }));
  };
  const selectOptions = selectOptionsInit(props?.options, INPUT_ENUM_SEPARATOR);
  const defaultStyleParams = {
    error: null,
    isTouched: false,
    isDisabled: false,
  };

  return (
    <React.Fragment>
      {props.attributeType === CustomAttributeType.MULTISELECT ? (
        <FormGroup>
          <Label for={props.id}>{props.label}</Label>
          <Controller
            name={props.name.replaceAll('.', '_#_')}
            control={props.control}
            defaultValue={''}
            render={({ field: { onChange, value } }) => (
              <Select
                styles={generateSelectStyles(defaultStyleParams)}
                value={
                  Array.isArray(value)
                    ? value.map((val: string) => ({ label: val, value: val })) // Dacă value este array
                    : value
                    ? value.split('|').map((val: string) => ({ label: val, value: val })) // Dacă value este string
                    : []
                }
                isMulti
                options={selectOptions}
                closeMenuOnSelect={false}
                data-cy={props['data-cy']}
                key={props.name}
                id={props.id}
                onChange={selectedOptions => {
                  // Se transforma array-ul de obiecte selectate într-un string concatenat cu separatorul "|"
                  const selectedValues = selectedOptions.map(option => option.value).join('|');
                  onChange(selectedValues); // Se trimite stringul concatenat în formular
                }}
              />
            )}
          />
        </FormGroup>
      ) : props.attributeType === CustomAttributeType.ENUMERATION ? (
        <FormGroup>
          <Label for={props.id}>{props.label}</Label>
          <Controller
            name={props.name.replaceAll('.', '_#_')}
            control={props.control}
            defaultValue={''}
            render={({ field: { onChange, value } }) => (
              <Select
                styles={generateSelectStyles(defaultStyleParams)}
                value={value ? { label: value, value } : null}
                isMulti={false}
                isClearable={true}
                options={selectOptions}
                closeMenuOnSelect={true}
                data-cy={props['data-cy']}
                key={props.name}
                id={props.id}
                onChange={selectedOption => {
                  const selectedValue = selectedOption ? selectedOption.value : '';
                  onChange(selectedValue);
                }}
              />
            )}
          />
        </FormGroup>
      ) : props.attributeType === CustomAttributeType.BOOLEAN ? (
        <FormGroup>
          <Label for={props.id}>{props.label}</Label>
          <Controller
            name={props.name.replaceAll('.', '_#_')}
            control={props.control}
            defaultValue={null}
            render={({ field: { onChange, value } }) => {
              return (
                <select
                  className="form-control"
                  value={value === true ? 'true' : value === false ? 'false' : ''}
                  data-cy={props['data-cy']}
                  key={props.name}
                  id={props.id}
                  onChange={event => {
                    const selectedValue = event.target.value === '' ? null : event.target.value === 'true' ? true : false;
                    onChange(selectedValue);
                  }}
                >
                  <option value=""> </option>
                  <option value="true">{translate('global.yes')}</option>
                  <option value="false">{translate('global.no')}</option>
                </select>
              );
            }}
          />
        </FormGroup>
      ) : props.attributeType === CustomAttributeType.DATE ? (
        <div className="date-picker-container mb-3">
          <Controller
            control={props.control}
            name={props.name.replaceAll('.', '_#_')}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="form-group">
                <label htmlFor={props.id} className="form-label">
                  {props.label}
                </label>
                <div className="position-relative">
                  <DatePicker
                    selected={value ? new Date(value) : new Date()}
                    onChange={date => {
                      onChange(date ? moment(date, convertDateFormat(props?.locale, 'date')).toDate() : null);
                    }}
                    value={
                      value ? moment(value).format(convertDateFormat(props?.locale, 'date')) : convertDateFormat(props?.locale, 'date')
                    }
                    onBlur={onBlur}
                    locale={props?.locale === 'ro' ? ro : 'en'}
                    todayButton={<Translate contentKey="global.today">Today</Translate>}
                    showMonthDropdown
                    showYearDropdown
                    className="form-control"
                    ref={ref}
                    id={props.id}
                    data-cy={props['data-cy']}
                  />
                  <span
                    onClick={() => {
                      onChange(null);
                    }}
                    className="fw-semibold close-icon-custom"
                  >
                    {value ? 'X' : ''}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
      ) : (
        <ValidatedField
          key={props.name}
          register={props.register}
          label={props.label}
          id={props.id}
          name={props.name.replaceAll('.', '_#_')}
          data-cy={props['data-cy']}
          type={type}
          defaultValue={defaultValue}
        ></ValidatedField>
      )}
    </React.Fragment>
  );
};

export const ReactSelectComponents = {
  DropdownIndicator: null,
  ClearIndicator: null,
};

export interface CreatableSelecOption {
  readonly label: string;
  readonly value: string;
}

export const createCreatableSelectOption = (label: string) => ({
  label,
  value: label,
});
