import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-jhipster';
import { faCancel, faCheck } from '@fortawesome/free-solid-svg-icons';

export default function ShowCustomAttributes() {
  const [showCustomAttributes, setShowCustomAttributes] = useState(localStorage.getItem('TABLE_showCustomAttributes') === 'true');

  const handleClick = () => {
    localStorage.setItem('TABLE_showCustomAttributes', String(!showCustomAttributes));
    setShowCustomAttributes(!showCustomAttributes);
    window.location.reload();
  };

  useEffect(() => {}, [showCustomAttributes]);

  return (
    <Button className="me-2" color={showCustomAttributes ? 'success' : 'danger'} onClick={handleClick}>
      <FontAwesomeIcon icon={showCustomAttributes ? faCheck : faCancel} />
      <Translate contentKey="global.showCustomAttributes" />
    </Button>
  );
}
