import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IEmployee } from 'app/shared/model/employee.model';
import React, { useEffect, useRef, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Button, Card, Col, Row } from 'reactstrap';
import { IPerson } from 'app/shared/model/person.model';
import './my-profile.scss';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import axios from 'axios';
import { useQuery } from 'react-query';
import { faArrowLeft, faArrowDown, faShieldHalved } from '@fortawesome/free-solid-svg-icons';

interface IAccount {
  id?: string | null;
  login?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  imageUrl?: string | null;
  activated?: boolean;
  langKey?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
  employee: IEmployee;
}

export const MyProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const urlKeyClock: string = useAppSelector(state => state.applicationProfile.keycloakUrl);
  const user: IAccount = useAppSelector(state => state.authentication.account);
  const employee: IEmployee = user.employee;
  const person: IPerson = user.employee.person;

  const [activeDropdowns, setActiveDropdowns] = useState([0, 0, 0]);
  const dropdownRefs = useRef([]);

  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);

  const startProcessQueryPerson = async () => {
    return await axios.get(`api/custom-attribute-definitions-for-objectType/${CustomAttributeApplicableObjectType.PERSON}`);
  };

  const { isSuccess, data: customAttributePerson } = useQuery('person', startProcessQueryPerson, {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const switchDropdown = event => {
    const id = parseInt(event.currentTarget.getAttribute('data-dropdownId'), 10);
    setActiveDropdowns(prevState => {
      const newState = [...prevState];
      newState[id] = newState[id] ? 0 : 1;
      return newState;
    });
  };

  useEffect(() => {
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.EMPLOYEE));
  }, []);
  useEffect(() => {}, [activeDropdowns]);

  return (
    <Card className="jh-card h-100">
      <h2 id="my-profile" data-cy="MyProfile">
        <Translate contentKey="iamdentityApp.myProfile.title">My Profile</Translate>
      </h2>
      <div className="my-profile">
        <Card className="card-grey dropdown p-3">
          <div className={'button'} onClick={switchDropdown} data-dropdownId={0}>
            <h4>
              <Translate contentKey="iamdentityApp.myProfile.userData.title">User Data</Translate>
            </h4>
            <FontAwesomeIcon icon={faArrowLeft} size={'lg'} className={activeDropdowns[0] ? 'rotate' : ''} />
          </div>
          <div
            className={`content`}
            ref={el => (dropdownRefs.current[0] = el)}
            style={{ height: activeDropdowns[0] ? dropdownRefs.current[0]?.scrollHeight + 'px' : '0px' }}
          >
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.myProfile.userData.logIn">LogIn</Translate>
              <div className={'form-control'}>{user.login}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.myProfile.userData.email">Email</Translate>
              <div className={'form-control'}>{user.email}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.myProfile.userData.firstName">First Name</Translate>
              <div className={'form-control'}>{user.firstName}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.myProfile.userData.lastName">Last Name</Translate>
              <div className={'form-control'}>{user.lastName}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.myProfile.userData.language">Language</Translate>
              <div className={'form-control'}>{user.langKey}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="global.field.createdBy">Created By</Translate>
              <div className={'form-control'}>{user.createdBy ? user.createdBy : ''}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="global.field.createdDate">Created Date</Translate>
              <div className={'form-control'}>
                {user.createdDate ? <TextFormat value={user.createdDate} type="date" format={convertDateFormat(locale, 'date')} /> : null}
              </div>
            </div>
            <div className={'child'}>
              <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              <div className={'form-control'}>{user.lastModifiedBy ? user.lastModifiedBy : ''}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              <div className={'form-control'}>
                {user.lastModifiedDate ? (
                  <TextFormat value={user.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </div>
            </div>
          </div>
        </Card>
        <Card className="card-grey dropdown p-3">
          <div className={'button'} onClick={switchDropdown} data-dropdownId={1}>
            <h4>
              <Translate contentKey="iamdentityApp.myProfile.employeeData.title">Employee Data</Translate>
            </h4>
            <FontAwesomeIcon icon={faArrowLeft} size={'lg'} className={activeDropdowns[1] ? 'rotate' : ''} />
          </div>
          <div
            className={`content`}
            ref={el => (dropdownRefs.current[1] = el)}
            style={{ height: activeDropdowns[1] ? dropdownRefs.current[1]?.scrollHeight + 'px' : '0px' }}
          >
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.employee.code">Code</Translate>
              <div className={'form-control'}>{employee?.code}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.employee.contractStartDate">Contract Start Date</Translate>
              <div className={'form-control'}>
                {employee?.contractStartDate ? (
                  <TextFormat type="date" value={employee.contractStartDate} format={convertDateFormat(locale, 'date')} />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.employee.contractEndDate">Contract End Date</Translate>
              <div className={'form-control'}>
                {employee?.contractEndDate ? (
                  <TextFormat type="date" value={employee.contractEndDate} format={convertDateFormat(locale, 'date')} />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={'child'}>
              <span>
                <Translate contentKey="iamdentityApp.employee.organizationUnit">Organization Unit</Translate>
              </span>
              <div className={'form-control'}>
                {employee?.organizationUnit?.name ? (
                  <Link to={`/organization-unit/${employee?.organizationUnit?.id}`}>{employee?.organizationUnit?.name}</Link>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={'child'}>
              <span>
                <Translate contentKey="iamdentityApp.employee.job">Job</Translate>
              </span>
              <div className={'form-control'}>
                {employee?.job?.name ? <Link to={`/job-definition/${employee?.job?.id}`}>{employee?.job?.name}</Link> : ''}
              </div>
            </div>
            <div className={'child'}>
              <span>
                <Translate contentKey="iamdentityApp.employee.superior">Superior</Translate>
              </span>
              <div className={'form-control'}>
                {employee?.superior ? <Link to={`/employee/${employee?.superior.id}`}>{employee?.superior?.code}</Link> : ''}
              </div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.employee.location">Location</Translate>
              <div className={'form-control'}>{employee?.location?.name}</div>
            </div>
            {customAttributeDefinitions?.map(cad => (
              <React.Fragment key={cad.id}>
                {foundCustomAttributeID(cad, employee, locale) && (
                  <>
                    <div key={cad.displayName} className={'child'}>
                      <span id={cad.displayName}>{cad.displayName}</span>
                      <div className={'form-control'}>{findCustomAttributeValueByKey(cad, employee, locale)}</div>
                    </div>
                  </>
                )}
              </React.Fragment>
            ))}
            <div className={'child'}>
              <Translate contentKey="global.field.createdBy">Created By</Translate>
              <div className={'form-control'}>{employee.createdBy ? employee.createdBy : ''}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="global.field.createdDate">Created Date</Translate>
              <div className={'form-control'}>
                {employee.createdDate ? (
                  <TextFormat value={employee.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </div>
            </div>
            <div className={'child'}>
              <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              <div className={'form-control'}>{employee.lastModifiedBy ? employee.lastModifiedBy : ''}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              <div className={'form-control'}>
                {employee.lastModifiedDate ? (
                  <TextFormat value={employee.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </div>
            </div>
          </div>
        </Card>
        <Card className="card-grey dropdown p-3">
          <div className={'button'} onClick={switchDropdown} data-dropdownId={2}>
            <h4>
              <Translate contentKey="iamdentityApp.myProfile.personData.title">Person Data</Translate>
            </h4>
            <FontAwesomeIcon icon={faArrowLeft} size={'lg'} className={activeDropdowns[2] ? 'rotate' : ''} />
          </div>
          <div
            className={`content`}
            ref={el => (dropdownRefs.current[2] = el)}
            style={{ height: activeDropdowns[2] ? dropdownRefs.current[2]?.scrollHeight + 'px' : '0px' }}
          >
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.person.name">Name</Translate>
              <div className={'form-control'}>{person?.name}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.person.firstName">First Name</Translate>
              <div className={'form-control'}>{person?.firstName}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.person.nationalIdentifier">National Identifier</Translate>
              <div className={'form-control'}>{person?.nationalIdentifier}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.person.communicationEmail">Communication Email</Translate>
              <div className={'form-control'}>{person?.communicationEmail}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.person.phoneNumber">Phone Number</Translate>
              <div className={'form-control'}>{person?.phoneNumber}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.person.dateOfBirth">Date Of Birth</Translate>
              <div className={'form-control'}>
                {person?.dateOfBirth ? (
                  <TextFormat type="date" value={person?.dateOfBirth} format={convertDateFormat(locale, 'date')} />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={'child'}>
              <Translate contentKey="iamdentityApp.person.nationality">Nationality</Translate>
              <div className={'form-control'}>{person?.nationality}</div>
            </div>
            {isSuccess &&
              customAttributePerson.data?.map(cad => (
                <React.Fragment key={cad.id}>
                  {foundCustomAttributeID(cad, person, locale) && (
                    <>
                      <div key={cad.displayName} className={'child'}>
                        <span id={cad.displayName}>{cad.displayName}</span>
                        <div className={'form-control'}>{findCustomAttributeValueByKey(cad, person, locale)}</div>
                      </div>
                    </>
                  )}
                </React.Fragment>
              ))}
            <div className={'child'}>
              <Translate contentKey="global.field.createdBy">Created By</Translate>
              <div className={'form-control'}>{person.createdBy ? person.createdBy : ''}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="global.field.createdDate">Created Date</Translate>
              <div className={'form-control'}>
                {person.createdDate ? (
                  <TextFormat value={person.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </div>
            </div>
            <div className={'child'}>
              <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              <div className={'form-control'}>{person.lastModifiedBy ? person.lastModifiedBy : ''}</div>
            </div>
            <div className={'child'}>
              <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              <div className={'form-control'}>
                {person.lastModifiedDate ? (
                  <TextFormat value={person.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <div className="btn-group flex-btn-group-container max-width">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
            <FontAwesomeIcon icon="arrow-left" />
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          <a href={urlKeyClock} className={'ms-1'}>
            <Button id="save" data-cy="saveButton" color="primary">
              <FontAwesomeIcon icon={faShieldHalved} />
              <span className="d-none d-md-inline">
                <Translate contentKey="iamdentityApp.myProfile.accountSecurityDetails">Account Security Details</Translate>
              </span>
            </Button>
          </a>
        </div>
      </div>
    </Card>
  );
};

export default MyProfile;
