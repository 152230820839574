/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './recertification-session-user-identity.reducer';
import { IRecertificationSessionUserIdentity } from 'app/shared/model/recertification-session-user-identity.model';
import { TextFormat } from 'react-jhipster';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';

export const RecertificationSessionUserIdentityDetail = () => {
  const dispatch = useAppDispatch();

  const { recertificationSessionUserIdentityId: id } = useParams<'recertificationSessionUserIdentityId'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.USER_IDENTITY));
  }, []);

  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const recertificationSessionUserIdentityEntity: IRecertificationSessionUserIdentity = useAppSelector(
    state => state.recertificationSessionUserIdentity.entity
  );
  const locale = useAppSelector(state => state.locale.currentLocale);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="recertificationSessionUserIdentityDetailsHeading">
          <Translate contentKey="iamdentityApp.recertificationSessionUserIdentity.detail.title">
            Recertification Session User Identity
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>:
            </span>
          </dt>
          <dd>{recertificationSessionUserIdentityEntity.id ? recertificationSessionUserIdentityEntity.id : ''}</dd>
          <dt>
            <span id="recertificationSessionName">
              <Translate contentKey="iamdentityApp.recertificationSession.detail.title">Recertification Session</Translate>:
            </span>
          </dt>
          <dd>
            {recertificationSessionUserIdentityEntity.recertificationSession
              ? recertificationSessionUserIdentityEntity.recertificationSession.name
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.application.detail.title">Application</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserIdentityEntity.userIdentity ? (
              <Link
                to={`/application/${
                  recertificationSessionUserIdentityEntity.userIdentity.application
                    ? recertificationSessionUserIdentityEntity.userIdentity.application.id
                    : ''
                }`}
              >
                {recertificationSessionUserIdentityEntity.userIdentity.application
                  ? recertificationSessionUserIdentityEntity.userIdentity.application.name
                  : ''}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.userIdentity.userName">Username</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserIdentityEntity.userIdentity ? (
              <Link
                to={`/user-identity/${
                  recertificationSessionUserIdentityEntity.userIdentity.userName
                    ? recertificationSessionUserIdentityEntity.userIdentity.id
                    : ''
                }`}
              >
                {recertificationSessionUserIdentityEntity.userIdentity.userName
                  ? recertificationSessionUserIdentityEntity.userIdentity.userName
                  : ''}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.userIdentity.startDate">Valid From</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserIdentityEntity.userIdentity?.startDate ? (
              <TextFormat
                value={recertificationSessionUserIdentityEntity.userIdentity.startDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.userIdentity.endDate">Valid To</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserIdentityEntity.userIdentity?.endDate ? (
              <TextFormat
                value={recertificationSessionUserIdentityEntity.userIdentity.endDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          {recertificationSessionUserIdentityEntity.revoked !== null && recertificationSessionUserIdentityEntity.revoked !== undefined ? (
            <div>
              <dt>
                <Translate contentKey="iamdentityApp.recertificationSessionUserIdentity.revoked">Revoked</Translate>:
              </dt>
              <dd>
                {recertificationSessionUserIdentityEntity.revoked === true ? (
                  recertificationSessionUserIdentityEntity.order ? (
                    <div>
                      <Translate contentKey="iamdentityApp.recertificationSessionUserIdentity.revokedByOrder">Revoked by Order</Translate>
                      :&nbsp;
                      <Link to={`/orders/${recertificationSessionUserIdentityEntity.order.id}`}>
                        {recertificationSessionUserIdentityEntity.order.id}
                      </Link>
                      &nbsp;
                      {recertificationSessionUserIdentityEntity?.order?.status ? (
                        <span className="fw-bold text-black d-inline-block">
                          ({recertificationSessionUserIdentityEntity?.order?.status})
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <Translate contentKey="iamdentityApp.recertificationSessionUserIdentity.revokedByOrder">Revoked by Order</Translate>
                  )
                ) : (
                  <Translate contentKey="iamdentityApp.recertificationSessionUserIdentity.confirmed">Confirmed</Translate>
                )}
              </dd>
            </div>
          ) : null}
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment key={cad.id}>
              {foundCustomAttributeID(cad, recertificationSessionUserIdentityEntity, locale) && (
                <>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, recertificationSessionUserIdentityEntity, locale)}</dd>
                </>
              )}
            </React.Fragment>
          ))}
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>:
          </dt>
          <dd>{recertificationSessionUserIdentityEntity.createdBy ? recertificationSessionUserIdentityEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserIdentityEntity.createdDate ? (
              <TextFormat
                value={recertificationSessionUserIdentityEntity.createdDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modify By</Translate>:
          </dt>
          <dd>{recertificationSessionUserIdentityEntity.lastModifiedBy ? recertificationSessionUserIdentityEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modify Date</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserIdentityEntity.lastModifiedDate ? (
              <TextFormat
                value={recertificationSessionUserIdentityEntity.lastModifiedDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
        </dl>
        <Button
          tag={Link}
          to={`/recertification-session/${
            recertificationSessionUserIdentityEntity.recertificationSession
              ? recertificationSessionUserIdentityEntity.recertificationSession.id
              : ''
          }`}
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
      </Col>
    </Row>
  );
};

export default RecertificationSessionUserIdentityDetail;
