import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, translate, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Table, Spinner } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from 'app/entities/audit/audit-modal';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';
import { getEntity } from './person.reducer';
import { getEntities as getEmployees } from '../employee/employee.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const PersonDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const personEntity = useAppSelector(state => state.person.entity);
  const employeeList = useAppSelector(state => state.employee.entities).filter(emp => emp.person.id === personEntity.id);
  const { id } = useParams<'id'>();
  const loading = useAppSelector(state => state.employee.loading || state.customAttributeDefinition.loading);
  const locale = useAppSelector(state => state.locale.currentLocale);

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PERSON));
    dispatch(getEmployees({ query: 'personId.equals=' + id.toString() }));
  }, []);

  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);

  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card">
            <h2 data-cy="personDetailsHeading">
              <Translate contentKey="iamdentityApp.person.detail.title">Person</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{personEntity.id}</dd>
              <dt>
                <span id="name">
                  <Translate contentKey="iamdentityApp.person.name">Name</Translate>
                </span>
              </dt>
              <dd>{personEntity.name}</dd>
              <dt>
                <span id="firstName">
                  <Translate contentKey="iamdentityApp.person.firstName">First Name</Translate>
                </span>
              </dt>
              <dd>{personEntity.firstName}</dd>
              <dt>
                <span id="nationalIdentifier">
                  <Translate contentKey="iamdentityApp.person.nationalIdentifier">National Identifier</Translate>
                </span>
              </dt>
              <dd>{personEntity.nationalIdentifier}</dd>
              <dt>
                <span id="gender">
                  <Translate contentKey="iamdentityApp.person.gender">Gender</Translate>
                </span>
              </dt>
              <dd>{personEntity.gender}</dd>
              <dt>
                <span id="phoneNumber">
                  <Translate contentKey="iamdentityApp.person.phoneNumber">Phone Number</Translate>
                </span>
              </dt>
              <dd>{personEntity.phoneNumber}</dd>
              <dt>
                <span id="communicationEmail">
                  <Translate contentKey="iamdentityApp.person.communicationEmail">Communication Email</Translate>
                </span>
              </dt>
              <dd>{personEntity.communicationEmail}</dd>
              <dt>
                <span id="dateOfBirth">
                  <Translate contentKey="iamdentityApp.person.dateOfBirth">Date Of Birth</Translate>
                </span>
              </dt>
              <dd>
                {personEntity.dateOfBirth ? (
                  <TextFormat value={personEntity.dateOfBirth} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="nationality">
                  <Translate contentKey="iamdentityApp.person.nationality">Nationality</Translate>
                </span>
              </dt>
              <dd>{personEntity?.nationalIdentifier}</dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment key={cad.id}>
                  {foundCustomAttributeID(cad, personEntity, locale) && (
                    <>
                      <dt>
                        <span id={cad.displayName}>{cad.displayName}</span>
                      </dt>
                      <dd>{findCustomAttributeValueByKey(cad, personEntity, locale)}</dd>
                    </>
                  )}
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{personEntity.createdBy ? personEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {personEntity.createdDate ? (
                  <TextFormat value={personEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{personEntity.lastModifiedBy ? personEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {personEntity.lastModifiedDate ? (
                  <TextFormat value={personEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>

            <Row>
              <Col className="noPad">
                <Button
                  block
                  id="cancel-save"
                  data-cy="entityDetailsBackButton"
                  onClick={() => navigate(-1)}
                  color="info"
                  style={{ borderRadius: 0 }}
                >
                  <FontAwesomeIcon icon="arrow-left" />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              </Col>
              <Col className="noPad">
                <Button block tag={Link} to={`/person/${personEntity.id}/edit`} color="warning" style={{ borderRadius: 0 }}>
                  <FontAwesomeIcon icon="pencil-alt" />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.edit">Edit</Translate>
                  </span>
                </Button>
              </Col>
              <Col className="noPad">
                <Button block onClick={toggleAuditModal} color="light" style={{ borderRadius: 0 }}>
                  <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.history">History</Translate>
                  </span>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md="9">
          <Card body>
            <h2 id="employee-heading" data-cy="EmployeeHeading" className="text-primary">
              <Translate contentKey="iamdentityApp.employee.home.title">Employees</Translate>
            </h2>
            <div className="table-responsive">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="text-center">
                      <Translate contentKey="iamdentityApp.employee.code">Code</Translate>
                    </th>
                    <th className="text-center">
                      <Translate contentKey="iamdentityApp.employee.person">Person</Translate>
                    </th>
                    <th className="text-center">
                      <Translate contentKey="iamdentityApp.employee.job">Job</Translate>
                    </th>
                    <th className="text-center">
                      <Translate contentKey="iamdentityApp.employee.organizationUnit">Organization Unit</Translate>
                    </th>
                    <th className="text-center">
                      <Translate contentKey="iamdentityApp.employee.location">Location</Translate>
                    </th>
                    <th className="text-center">
                      <Translate contentKey="iamdentityApp.employee.employeeStatus">Employee Status</Translate>
                    </th>
                    <th className="text-center">
                      <Translate contentKey="iamdentityApp.employee.contractStartDate">Contract Start Date</Translate>
                    </th>
                    <th className="text-center">
                      <Translate contentKey="iamdentityApp.employee.contractEndDate">Contract End Date</Translate>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeList.map((employee, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>
                        <Button tag={Link} to={`/employee/${employee.id}`} color="link" size="sm">
                          {employee.code}
                        </Button>
                      </td>
                      <td>{employee.person ? <Link to={`/person/${employee.person.id}`}>{employee.fullName}</Link> : ''}</td>
                      <td>{employee.job ? <Link to={`/job-definition/${employee.job.id}`}>{employee.job.name}</Link> : ''}</td>
                      <td>
                        {employee.organizationUnit ? (
                          <Link to={`/organization-unit/${employee.organizationUnit.id}`}>{employee.organizationUnit.name}</Link>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>{employee.location ? <Link to={`/location/${employee.location.id}`}>{employee.location.name}</Link> : ''}</td>
                      <td>{translate(`iamdentityApp.EmployeeStatus.${employee.employeeStatus}`)}</td>
                      <td>
                        {employee.contractStartDate ? (
                          <TextFormat type="date" value={employee.contractStartDate} format={convertDateFormat(locale, 'date')} />
                        ) : null}
                      </td>
                      <td>
                        {employee.contractEndDate ? (
                          <TextFormat type="date" value={employee.contractEndDate} format={convertDateFormat(locale, 'date')} />
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {(!employeeList || employeeList.length < 1) && !loading && (
                <div className="alert alert-warning mt-1">
                  <Translate contentKey="iamdentityApp.employee.home.notFound">No Employees found</Translate>
                </div>
              )}
              {loading ? <Spinner /> : null}
            </div>
          </Card>
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.PERSON} />
    </React.Fragment>
  );
};

export default PersonDetail;
