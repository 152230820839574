import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './in-organization-unit.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';

export const InOrganizationUnitDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const inOrganizationUnitEntity = useAppSelector(state => state.inOrganizationUnit.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <Row>
      <Col md="12">
        <Card className="jh-card">
          <h2 data-cy="inOrganizationUnitDetailsHeading">
            <Translate contentKey="iamdentityApp.inOrganizationUnit.detail.title">InOrganizationUnit</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="name">
                <Translate contentKey="iamdentityApp.inOrganizationUnit.name">Name</Translate>
              </span>
            </dt>
            <dd>{inOrganizationUnitEntity.name}</dd>
            <dt>
              <span id="code">
                <Translate contentKey="iamdentityApp.inOrganizationUnit.code">Code</Translate>
              </span>
            </dt>
            <dd>{inOrganizationUnitEntity.code}</dd>
            <dt>
              <span id="shortName">
                <Translate contentKey="iamdentityApp.inOrganizationUnit.shortName">Short Name</Translate>
              </span>
            </dt>
            <dd>{inOrganizationUnitEntity.shortName}</dd>
            <dt>
              <span id="validFrom">
                <Translate contentKey="iamdentityApp.inOrganizationUnit.validFrom">Valid From</Translate>
              </span>
            </dt>
            <dd>
              {inOrganizationUnitEntity.validFrom ? (
                <TextFormat value={inOrganizationUnitEntity.validFrom} type="date" format={APP_LOCAL_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="validTo">
                <Translate contentKey="iamdentityApp.inOrganizationUnit.validTo">Valid To</Translate>
              </span>
            </dt>
            <dd>
              {inOrganizationUnitEntity.validTo ? (
                <TextFormat value={inOrganizationUnitEntity.validTo} type="date" format={APP_LOCAL_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="organizationUnitTypeName">
                <Translate contentKey="iamdentityApp.inOrganizationUnit.organizationUnitTypeName">Organization Unit Type Name</Translate>
              </span>
            </dt>
            <dd>{inOrganizationUnitEntity.organizationUnitTypeName}</dd>
            <dt>
              <span id="parentOrganizationUnitCode">
                <Translate contentKey="iamdentityApp.inOrganizationUnit.parentOrganizationUnitCode">
                  Parent Organization Unit Code
                </Translate>
              </span>
            </dt>
            <dd>{inOrganizationUnitEntity.parentOrganizationUnitCode}</dd>
            <dt>
              <span id="locationCode">
                <Translate contentKey="iamdentityApp.inOrganizationUnit.locationCode">Location Code</Translate>
              </span>
            </dt>
            <dd>{inOrganizationUnitEntity.locationCode}</dd>
            <dt>
              <span id="managerEmployeeCode">
                <Translate contentKey="iamdentityApp.inOrganizationUnit.managerEmployeeCode">Manager Employee Code</Translate>
              </span>
            </dt>
            <dd>{inOrganizationUnitEntity.managerEmployeeCode}</dd>
            {customAttributeDefinitions?.map(cad => (
              <React.Fragment key={cad.id}>
                {foundCustomAttributeID(cad, inOrganizationUnitEntity, locale) && (
                  <>
                    <dt>
                      <span id={cad.displayName}>{cad.displayName}</span>
                    </dt>
                    <dd>{findCustomAttributeValueByKey(cad, inOrganizationUnitEntity, locale)}</dd>
                  </>
                )}
              </React.Fragment>
            ))}
            {inOrganizationUnitEntity?.additionalAttributes?.length > 0 &&
              inOrganizationUnitEntity.additionalAttributes.map(at => (
                <React.Fragment key={at.id}>
                  <dt>
                    <span id={at.id}>{at.attributeName}</span>
                  </dt>
                  <dd>{at.attributeValue}</dd>
                </React.Fragment>
              ))}
          </dl>
          <Button
            style={{ width: 'fit-content' }}
            tag={Link}
            to="/in-organization-unit"
            replace
            color="info"
            data-cy="entityDetailsBackButton"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default InOrganizationUnitDetail;
