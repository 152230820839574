/* eslint-disable react/jsx-key */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByProfileDefinitionId as getApplicationsByProfileDefinitionId } from 'app/entities/application/application.reducer';
import ProfileSpecificPermissionDeleteDialog from 'app/entities/profile-specific-permission/profile-specific-permission-delete-dialog';
import ProfileSpecificPermissionDetail from 'app/entities/profile-specific-permission/profile-specific-permission-detail';
import ProfileSpecificPermissionUpdate from 'app/entities/profile-specific-permission/profile-specific-permission-update';
import { getEntitiesByProfileDefinitionId as getProfileSpecificPermissionByProfileDefinitionId } from 'app/entities/profile-specific-permission/profile-specific-permission.reducer';
import ProfileUserGroupDeleteDialog from 'app/entities/profile-user-group/profile-user-group-delete-dialog';
import ProfileUserGroupDetail from 'app/entities/profile-user-group/profile-user-group-detail';
import ProfileUserGroupUpdate from 'app/entities/profile-user-group/profile-user-group-update';
import { getEntitiesByProfileDefinitionId as getProfileUserGroupsByProfileDefinitionId } from 'app/entities/profile-user-group/profile-user-group.reducer';
import ProfileUserIdentityRoleDeleteDialog from 'app/entities/profile-user-identity-role/profile-user-identity-role-delete-dialog';
import ProfileUserIdentityRoleDetail from 'app/entities/profile-user-identity-role/profile-user-identity-role-detail';
import ProfileUserIdentityRoleUpdate from 'app/entities/profile-user-identity-role/profile-user-identity-role-update';
import { getEntitiesByProfileDefinitionId as getProfileUserIdentityRolesByProfileDefinitionId } from 'app/entities/profile-user-identity-role/profile-user-identity-role.reducer';
import ProfileUserIdentityDeleteDialog from 'app/entities/profile-user-identity/profile-user-identity-delete-dialog';
import ProfileUserIdentityDetail from 'app/entities/profile-user-identity/profile-user-identity-detail';
import ProfileUserIdentityUpdate from 'app/entities/profile-user-identity/profile-user-identity-update';
import { getEntitiesByProfileDefinitionId as getProfileUserIdentitiesByProfileDefinitionId } from 'app/entities/profile-user-identity/profile-user-identity.reducer';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { IApplication } from 'app/shared/model/application.model';
import { IProfileDefinition } from 'app/shared/model/profile-definition.model';
import { IProfileSpecificPermission } from 'app/shared/model/profile-specific-permission.model';
import { IProfileUserGroup } from 'app/shared/model/profile-user-group.model';
import { IProfileUserIdentityRole } from 'app/shared/model/profile-user-identity-role.model';
import { IProfileUserIdentity } from 'app/shared/model/profile-user-identity.model';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { TextFormat, translate, Translate } from 'react-jhipster';
import { Link, Route, useLocation } from 'react-router-dom';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Input,
  InputGroup,
  Spinner,
  UncontrolledAccordion,
} from 'reactstrap';
import AssignedProfileRoleGroupsTable from './assigned-profile-role-groups-table';
import AssignedProfileRolesTable from './assigned-profile-roles-table';
import AssignedProfileSpecificPermissionsTable from './assigned-profile-specific-permissions-table';
import { default as ProfileInteractionModal } from './profile-interaction-modal';
import { convertDateFormat } from 'app/shared/util/date-utils';

const generateProfileUserIdentityUrl = (
  application: IApplication,
  profileDefinition: IProfileDefinition,
  profileUserIdentityId?: number,
  action?: 'VIEW' | 'DELETE'
) => {
  let result = '';
  if (profileUserIdentityId) {
    result += `profile-user/${profileUserIdentityId}${action ? (action == 'DELETE' ? '/delete' : '/view') : ''}`;
  } else {
    result += 'profile-user';
  }

  result += `?profileDefinition.id=${profileDefinition.id}&profileDefinition.name=${profileDefinition.name}&profileDefinition.startDate=${profileDefinition.startDate}&profileDefinition.endDate=${profileDefinition.endDate}`;

  if (application) {
    result += `&application.id=${application.id}&application.name=${application.name}&application.startDate=${
      application.startDate
    }&application.endDate=${application.endDate}&application.userDependsOnApplication=${
      application?.userDependsOnApplication ? application?.userDependsOnApplication?.id : ''
    }`;
  }

  return encodeURI(result);
};

export const generateAssignedRoleUrl = (
  profileUserIdentity: IProfileUserIdentity,
  profileUserIdentityRoleId?: number | null,
  action?: 'VIEW' | 'DELETE' | null,
  application?: IApplication
) => {
  let result = '';
  if (profileUserIdentityRoleId) {
    result += `profile-assigned-role/${profileUserIdentityRoleId}${action ? (action == 'DELETE' ? '/delete' : '/view') : ''}`;
  } else {
    result += 'profile-assigned-role';
  }

  if (profileUserIdentityRoleId == null && action == null && application) {
    return encodeURI(
      (result += `?profileUserIdentity.id=${profileUserIdentity.id}&profileUserIdentity.profileDefinitionName=${profileUserIdentity.profileDefinitionName}&applicationId=${application.id}`)
    );
  }

  return encodeURI(
    (result += `?profileUserIdentity.id=${profileUserIdentity.id}&profileUserIdentity.profileDefinitionName=${profileUserIdentity.profileDefinitionName}`)
  );
};

export const generateAssignedRoleGroupUrl = (
  profileUserIdentity: IProfileUserIdentity,
  profileUserGroupId?: number | null,
  action?: 'VIEW' | 'DELETE' | null,
  application?: IApplication
) => {
  let result = '';

  if (profileUserGroupId) {
    result += `profile-assigned-role-group/${profileUserGroupId}${action ? (action == 'DELETE' ? '/delete' : '/view') : ''}`;
  } else {
    result += 'profile-assigned-role-group';
  }

  if (profileUserGroupId == null && action == null && application) {
    return encodeURI(
      (result += `?profileUserIdentity.id=${profileUserIdentity.id}&profileUserIdentity.profileDefinitionName=${profileUserIdentity.profileDefinitionName}&applicationId=${application.id}`)
    );
  }

  return encodeURI(
    (result += `?profileUserIdentity.id=${profileUserIdentity.id}&profileUserIdentity.profileDefinitionName=${profileUserIdentity.profileDefinitionName}`)
  );
};

export const generateAssignedSpecificPermissionUrl = (
  profileUserIdentity: IProfileUserIdentity,
  profileSpecificPermissionId?: number | null,
  action?: 'VIEW' | 'DELETE' | null,
  application?: IApplication
) => {
  let result = '';

  if (profileSpecificPermissionId) {
    result += `profile-assigned-specific-permission/${profileSpecificPermissionId}${
      action ? (action == 'DELETE' ? '/delete' : '/view') : ''
    }`;
  } else {
    result += 'profile-assigned-specific-permission';
  }

  if (profileSpecificPermissionId == null && action == null && application) {
    return encodeURI(
      (result += `?profileUserIdentity.id=${profileUserIdentity.id}&profileUserIdentity.profileDefinitionName=${profileUserIdentity.profileDefinitionName}&applicationId=${application.id}`)
    );
  }

  return encodeURI(
    (result += `?profileUserIdentity.id=${profileUserIdentity.id}&profileUserIdentity.profileDefinitionName=${profileUserIdentity.profileDefinitionName}`)
  );
};

interface IProfileUsersProps {
  profileDefinitionId: number;
  isProfileExpired: boolean;
}

interface IProfileApplication {
  application: IApplication;
  profileUserIdentities: IProfileUserIdentity[];
}

const ProfileUsers = ({ profileDefinitionId, isProfileExpired }: IProfileUsersProps) => {
  const dispatch = useAppDispatch();

  const profileDefinition = useAppSelector(state => state.profileDefinition.entity);
  const applicationList: IApplication[] = useAppSelector(state => state.application.entities);
  const profileUserIdentityList: IProfileUserIdentity[] = useAppSelector(state => state.profileUserIdentity.entities);
  const profileUserIdentityRoleList: IProfileUserIdentityRole[] = useAppSelector(state => state.profileUserIdentityRole.entities);
  const profileUserGroupList: IProfileUserGroup[] = useAppSelector(state => state.profileUserGroup.entities);
  const profileSpecificPermissionList: IProfileSpecificPermission[] = useAppSelector(state => state.profileSpecificPermission.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const loading = useAppSelector(state => state.application.loading || !profileDefinition);

  const completeApplications = useRef<IProfileApplication[]>([]);
  const [applications, setApplications] = useState<IProfileApplication[]>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (profileDefinitionId) {
      dispatch(getApplicationsByProfileDefinitionId(profileDefinitionId));
      dispatch(getProfileUserIdentitiesByProfileDefinitionId(profileDefinitionId));
      dispatch(getProfileUserIdentityRolesByProfileDefinitionId(profileDefinitionId));
      dispatch(getProfileUserGroupsByProfileDefinitionId(profileDefinitionId));
      dispatch(getProfileSpecificPermissionByProfileDefinitionId(profileDefinitionId));
    }
  }, [profileDefinitionId]);

  useEffect(() => {
    if (!loading) {
      const newApplications: IProfileApplication[] = applicationList.map(app => ({
        application: app,
        profileUserIdentities: profileUserIdentityList
          .filter(pui => pui.application?.id === app.id)
          .map(pui => ({
            ...pui,
            profileUserIdentityRoles: profileUserIdentityRoleList.filter(puir => puir.profileUserIdentity.id == pui.id),
            profileUserGroups: profileUserGroupList.filter(pug => pug.profileUserIdentity.id == pui.id),
            profileSpecificPermissions: profileSpecificPermissionList.filter(psp => psp.profileUserIdentity.id == pui.id),
          })),
      }));

      completeApplications.current = newApplications;
      setApplications(newApplications);
    }
  }, [loading, applicationList, profileUserIdentityList, profileUserIdentityRoleList, profileUserGroupList, profileSpecificPermissionList]);

  useEffect(() => {
    if (profileDefinitionId) {
      dispatch(getApplicationsByProfileDefinitionId(profileDefinitionId));
    }
  }, [profileUserIdentityList]);

  useEffect(() => {
    applySearch();
  }, [search]);

  const handleSearch = event => {
    setSearch(event.target.value);
  };

  const clearSearch = () => {
    setSearch('');
  };

  const applySearch = _.debounce(() => {
    setApplications(completeApplications.current.filter(app => app.application.name.toLowerCase().includes(search.toLowerCase())));
  }, 1000);

  return (
    <React.Fragment>
      {loading ? (
        <div className="entity-spinner-container">
          <Spinner color="primary">Loading...</Spinner>
        </div>
      ) : (
        <React.Fragment>
          <Col sm="12" className="mb-2">
            <InputGroup>
              <Input
                type="text"
                name="search"
                value={search}
                onChange={handleSearch}
                placeholder={translate('iamdentityApp.application.home.search')}
              />
              <Button type="reset" className="input-group-addon" onClick={clearSearch}>
                <FontAwesomeIcon icon="trash" />
              </Button>
            </InputGroup>
          </Col>
          <div className="mb-2 text-right">
            <Button
              disabled={isProfileExpired}
              tag={Link}
              to={generateProfileUserIdentityUrl(null, profileDefinition)}
              color="success"
              size="md"
              data-cy="entityEditButton"
            >
              <FontAwesomeIcon icon="plus" />{' '}
              <Translate contentKey="iamdentityApp.profileUserIdentity.home.addUserToApplication">Add User To Application</Translate>
            </Button>
          </div>
          <UncontrolledAccordion open="" defaultOpen={['0']} stayOpen>
            {applications.length ? (
              applications.map((app, index) => {
                const application = app.application;
                const profileUserIdentities = app.profileUserIdentities;
                const key = index.toString();
                const checkIfApplicationIsExpired = () => {
                  if (application.endDate == null) return false;
                  return new Date() > new Date(application.endDate);
                };
                return (
                  <AccordionItem key={`accordion-item-${key}`}>
                    <AccordionHeader className="accordion-application-header" targetId={key}>
                      <div className="flex-div full-width justify-space-between mr-1">
                        <div>
                          <Translate contentKey="iamdentityApp.application.detail.title">Application</Translate>: {application.name}
                        </div>
                      </div>
                    </AccordionHeader>
                    <AccordionBody accordionId={key}>
                      <div className="modal-footer mb-2">
                        <Button
                          disabled={checkIfApplicationIsExpired() || isProfileExpired}
                          tag={Link}
                          to={generateProfileUserIdentityUrl(application, profileDefinition)}
                          color="success"
                          size="md"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="plus" />{' '}
                          <Translate contentKey="iamdentityApp.profileUserIdentity.home.createLabel">
                            Create new Profile User Identity
                          </Translate>
                        </Button>
                      </div>
                      {profileUserIdentities.map(profileUserIdentity => {
                        const checkIfProfileUserIdentityExpired = () => {
                          if (profileUserIdentity.endDate == null) return false;
                          return new Date() > new Date(profileUserIdentity.endDate);
                        };
                        return (
                          <div className="details-grid-container details-grid-row">
                            <div className="summary-container">
                              <Card>
                                <Col className="dark-text">
                                  <div className="full-width btn-group flex-btn-group-container">
                                    <Button
                                      disabled={checkIfProfileUserIdentityExpired() || isProfileExpired}
                                      tag={Link}
                                      to={generateProfileUserIdentityUrl(application, profileDefinition, profileUserIdentity.id, 'VIEW')}
                                      className="btn-md"
                                      color="info"
                                      data-cy="entityDetailsButton"
                                    >
                                      <FontAwesomeIcon icon="eye" />
                                    </Button>
                                    <Button
                                      disabled={checkIfProfileUserIdentityExpired() || isProfileExpired}
                                      tag={Link}
                                      to={generateProfileUserIdentityUrl(application, profileDefinition, profileUserIdentity.id)}
                                      className="btn-md"
                                      color="warning"
                                      data-cy="entityEditButton"
                                    >
                                      <FontAwesomeIcon icon="pencil-alt" />
                                    </Button>
                                    <Button
                                      className="btn-md"
                                      tag={Link}
                                      to={generateProfileUserIdentityUrl(application, profileDefinition, profileUserIdentity.id, 'DELETE')}
                                      color="primary"
                                      data-cy="entityDeleteButton"
                                    >
                                      <FontAwesomeIcon icon="trash" />
                                    </Button>
                                  </div>
                                  <dl className="jh-entity-details">
                                    <dt>
                                      <Translate contentKey="iamdentityApp.profileUserIdentity.id">ID</Translate>
                                    </dt>
                                    <dd>{profileUserIdentity?.id}</dd>
                                    <dt>
                                      <Translate contentKey="iamdentityApp.profileUserIdentity.dependsOn">Depends On</Translate>
                                    </dt>
                                    <dd>
                                      {(profileUserIdentity?.dependsOn?.id ? profileUserIdentity.dependsOn.id : '') +
                                        (profileUserIdentity?.dependsOn?.description
                                          ? ' - ' + profileUserIdentity.dependsOn.description
                                          : '')}
                                    </dd>
                                    <dt>
                                      <span id="userNameExpression">
                                        <Translate contentKey="iamdentityApp.profileUserIdentity.userNameExpression">
                                          User Name Expression
                                        </Translate>
                                      </span>
                                    </dt>
                                    <dd>
                                      {profileUserIdentity.userNameExpression ? (
                                        <CompactExpressionBuilder
                                          tree={profileUserIdentity.userNameExpression}
                                          expressionId={`user-name-${profileUserIdentity?.id || ''}`}
                                        />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      <span id="alternativeUserNameExpression">
                                        <Translate contentKey="iamdentityApp.profileUserIdentity.alternativeUserNameExpression">
                                          Alternative User Name Expression
                                        </Translate>
                                      </span>
                                    </dt>
                                    <dd>
                                      {profileUserIdentity.alternativeUserNameExpression ? (
                                        <CompactExpressionBuilder
                                          tree={profileUserIdentity.alternativeUserNameExpression}
                                          expressionId={`alternative-user-name-${profileUserIdentity?.id || ''}`}
                                        />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      <span id="associatedEmailAddressExpression">
                                        <Translate contentKey="iamdentityApp.profileUserIdentity.associatedEmailAddressExpression">
                                          Associated Email Address Expression
                                        </Translate>
                                      </span>
                                    </dt>
                                    <dd>
                                      {profileUserIdentity.associatedEmailAddressExpression ? (
                                        <CompactExpressionBuilder
                                          tree={profileUserIdentity.associatedEmailAddressExpression}
                                          expressionId={`associated-email-address-${profileUserIdentity?.id || ''}`}
                                        />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      <span id="displayNameExpression">
                                        <Translate contentKey="iamdentityApp.profileUserIdentity.displayNameExpression">
                                          Display Name Expression
                                        </Translate>
                                      </span>
                                    </dt>
                                    <dd>
                                      {profileUserIdentity.displayNameExpression ? (
                                        <CompactExpressionBuilder
                                          tree={profileUserIdentity.displayNameExpression}
                                          expressionId={`display-name-${profileUserIdentity?.id || ''}`}
                                        />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      <span id="initialPasswordExpression">
                                        <Translate contentKey="iamdentityApp.profileUserIdentity.initialPasswordExpression">
                                          Initial Password Expression
                                        </Translate>
                                      </span>
                                    </dt>
                                    <dd>
                                      {profileUserIdentity.initialPasswordExpression ? (
                                        <CompactExpressionBuilder
                                          tree={profileUserIdentity.initialPasswordExpression}
                                          expressionId={`initial-password-${profileUserIdentity?.id || ''}`}
                                        />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      <span id="mustChangePasswordOnFirstLoginExpression">
                                        <Translate contentKey="iamdentityApp.profileUserIdentity.mustChangePasswordOnFirstLoginExpression">
                                          Must Change Password On First Login Expression
                                        </Translate>
                                      </span>
                                    </dt>
                                    <dd>
                                      {profileUserIdentity.mustChangePasswordOnFirstLoginExpression ? (
                                        <CompactExpressionBuilder
                                          tree={profileUserIdentity.mustChangePasswordOnFirstLoginExpression}
                                          expressionId={`must-change-password-on-first-login-${profileUserIdentity?.id || ''}`}
                                        />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      <span id="dateNextChangePasswordExpression">
                                        <Translate contentKey="iamdentityApp.profileUserIdentity.dateNextChangePasswordExpression">
                                          Date Next Change Password Expression
                                        </Translate>
                                      </span>
                                    </dt>
                                    <dd>
                                      {profileUserIdentity.dateNextChangePasswordExpression ? (
                                        <CompactExpressionBuilder
                                          tree={profileUserIdentity.dateNextChangePasswordExpression}
                                          expressionId={`date-next-change-password-${profileUserIdentity?.id || ''}`}
                                        />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      <span id="startDate">
                                        <Translate contentKey="iamdentityApp.profileUserIdentity.startDate">Start Date</Translate>
                                      </span>
                                    </dt>
                                    <dd>
                                      {profileUserIdentity.startDate ? (
                                        <TextFormat
                                          value={profileUserIdentity.startDate}
                                          type="date"
                                          format={convertDateFormat(locale, 'instant')}
                                        />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      <span id="endDate">
                                        <Translate contentKey="iamdentityApp.profileUserIdentity.endDate">End Date</Translate>
                                      </span>
                                    </dt>
                                    <dd>
                                      {profileUserIdentity.endDate ? (
                                        <TextFormat
                                          value={profileUserIdentity.endDate}
                                          type="date"
                                          format={convertDateFormat(locale, 'instant')}
                                        />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      <Translate contentKey="iamdentityApp.profileUserIdentity.profileDefinition">
                                        Profile Definition
                                      </Translate>
                                    </dt>
                                    <dd>{profileUserIdentity.profileDefinition ? profileUserIdentity.profileDefinition.name : ''}</dd>
                                    <dt>
                                      <Translate contentKey="iamdentityApp.profileUserIdentity.application">Application</Translate>
                                    </dt>
                                    <dd>{profileUserIdentity.application ? profileUserIdentity.application.name : ''}</dd>
                                  </dl>
                                </Col>
                              </Card>
                            </div>
                            <div className="content-container ml-1">
                              <UncontrolledAccordion
                                open=""
                                defaultOpen={['assigned-roles', 'assigned-role-groups', 'assigned-specific-permissions']}
                                stayOpen
                                flush
                              >
                                <AccordionItem>
                                  <AccordionHeader className="accordion-application-header" targetId="assigned-roles">
                                    <div className="flex-div full-width justify-space-between mr-1">
                                      <div>
                                        <Translate contentKey="iamdentityApp.orders.detail.assignedRoles">Assigned Roles</Translate>
                                      </div>
                                    </div>
                                  </AccordionHeader>
                                  <AccordionBody accordionId="assigned-roles">
                                    <div className="modal-footer mb-2">
                                      <Button
                                        disabled={checkIfProfileUserIdentityExpired() || isProfileExpired}
                                        tag={Link}
                                        to={generateAssignedRoleUrl(profileUserIdentity, null, null, application)}
                                        color="success"
                                        size="md"
                                        data-cy="entityEditButton"
                                      >
                                        <FontAwesomeIcon icon="plus" />{' '}
                                        <Translate contentKey="iamdentityApp.profileUserIdentityRole.home.createLabel">
                                          Create new Profile User Identity Role
                                        </Translate>
                                      </Button>
                                    </div>
                                    <AssignedProfileRolesTable
                                      profileRoles={profileUserIdentity.profileUserIdentityRoles}
                                      isProfileExpired={isProfileExpired}
                                      isProfileUserExpired={checkIfProfileUserIdentityExpired()}
                                    />
                                  </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                  <AccordionHeader className="accordion-application-header" targetId="assigned-role-groups">
                                    <div className="flex-div full-width justify-space-between mr-1">
                                      <div>
                                        <Translate contentKey="iamdentityApp.orders.detail.assignedRoleGroups">Assigned Roles</Translate>
                                      </div>
                                    </div>
                                  </AccordionHeader>
                                  <AccordionBody accordionId="assigned-role-groups">
                                    <div className="modal-footer mb-2">
                                      <Button
                                        disabled={checkIfProfileUserIdentityExpired() || isProfileExpired}
                                        tag={Link}
                                        to={generateAssignedRoleGroupUrl(profileUserIdentity, null, null, application)}
                                        color="success"
                                        size="md"
                                        data-cy="entityEditButton"
                                      >
                                        <FontAwesomeIcon icon="plus" />{' '}
                                        <Translate contentKey="iamdentityApp.profileUserGroup.home.createLabel">
                                          Create new Profile User Group
                                        </Translate>
                                      </Button>
                                    </div>
                                    <AssignedProfileRoleGroupsTable
                                      profileRoleGroups={profileUserIdentity.profileUserGroups}
                                      isProfileExpired={isProfileExpired}
                                      isProfileUserExpired={checkIfProfileUserIdentityExpired()}
                                    />
                                  </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                  <AccordionHeader className="accordion-application-header" targetId="assigned-specific-permissions">
                                    <div className="flex-div full-width justify-space-between mr-1">
                                      <div>
                                        <Translate contentKey="iamdentityApp.orders.detail.assignedSpecificPermissions">
                                          Assigned Specific Permissions
                                        </Translate>
                                      </div>
                                    </div>
                                  </AccordionHeader>
                                  <AccordionBody accordionId="assigned-specific-permissions">
                                    <div className="modal-footer mb-2">
                                      <Button
                                        disabled={checkIfProfileUserIdentityExpired() || isProfileExpired}
                                        tag={Link}
                                        to={generateAssignedSpecificPermissionUrl(profileUserIdentity, null, null, application)}
                                        color="success"
                                        size="md"
                                        data-cy="entityEditButton"
                                      >
                                        <FontAwesomeIcon icon="plus" />{' '}
                                        <Translate contentKey="iamdentityApp.profileSpecificPermission.home.createLabel">
                                          Create new Profile Specific Permission
                                        </Translate>
                                      </Button>
                                    </div>
                                    <AssignedProfileSpecificPermissionsTable
                                      profileSpecificPermissions={profileUserIdentity.profileSpecificPermissions}
                                      isProfileExpired={isProfileExpired}
                                      isProfileUserExpired={checkIfProfileUserIdentityExpired()}
                                    />
                                  </AccordionBody>
                                </AccordionItem>
                              </UncontrolledAccordion>
                            </div>
                          </div>
                        );
                      })}
                    </AccordionBody>
                  </AccordionItem>
                );
              })
            ) : (
              <div className="alert alert-warning">
                <Translate contentKey="iamdentityApp.application.home.notFound">Not found</Translate>
              </div>
            )}
          </UncontrolledAccordion>
        </React.Fragment>
      )}

      <ErrorBoundaryRoutes>
        <Route path="profile-user">
          <Route
            index
            element={
              <ProfileInteractionModal>
                <ProfileUserIdentityUpdate />
              </ProfileInteractionModal>
            }
          />
          <Route path=":profileUserIdentityId">
            <Route
              index
              element={
                <ProfileInteractionModal>
                  <ProfileUserIdentityUpdate />
                </ProfileInteractionModal>
              }
            />
            <Route path="delete" element={<ProfileUserIdentityDeleteDialog />} />
            <Route
              path="view"
              element={
                <ProfileInteractionModal>
                  <ProfileUserIdentityDetail />
                </ProfileInteractionModal>
              }
            />
          </Route>
        </Route>
        <Route path="profile-assigned-role">
          <Route
            index
            element={
              <ProfileInteractionModal>
                <ProfileUserIdentityRoleUpdate />
              </ProfileInteractionModal>
            }
          />
          <Route path=":profileUserIdentityRoleId">
            <Route
              index
              element={
                <ProfileInteractionModal>
                  <ProfileUserIdentityRoleUpdate />
                </ProfileInteractionModal>
              }
            />
            <Route path="delete" element={<ProfileUserIdentityRoleDeleteDialog />} />
            <Route
              path="view"
              element={
                <ProfileInteractionModal>
                  <ProfileUserIdentityRoleDetail />
                </ProfileInteractionModal>
              }
            />
          </Route>
        </Route>
        <Route path="profile-assigned-role-group">
          <Route
            index
            element={
              <ProfileInteractionModal>
                <ProfileUserGroupUpdate />
              </ProfileInteractionModal>
            }
          />
          <Route path=":profileUserGroupId">
            <Route
              index
              element={
                <ProfileInteractionModal>
                  <ProfileUserGroupUpdate />
                </ProfileInteractionModal>
              }
            />
            <Route path="delete" element={<ProfileUserGroupDeleteDialog />} />
            <Route
              path="view"
              element={
                <ProfileInteractionModal>
                  <ProfileUserGroupDetail />
                </ProfileInteractionModal>
              }
            />
          </Route>
        </Route>
        <Route path="profile-assigned-specific-permission">
          <Route
            index
            element={
              <ProfileInteractionModal>
                <ProfileSpecificPermissionUpdate />
              </ProfileInteractionModal>
            }
          />
          <Route path=":profileSpecificPermissionId">
            <Route
              index
              element={
                <ProfileInteractionModal>
                  <ProfileSpecificPermissionUpdate />
                </ProfileInteractionModal>
              }
            />
            <Route path="delete" element={<ProfileSpecificPermissionDeleteDialog />} />
            <Route
              path="view"
              element={
                <ProfileInteractionModal>
                  <ProfileSpecificPermissionDetail />
                </ProfileInteractionModal>
              }
            />
          </Route>
        </Route>
      </ErrorBoundaryRoutes>
    </React.Fragment>
  );
};

export default ProfileUsers;
