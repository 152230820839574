import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './in-user-group.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';

export const InUserGroupDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const inUserGroupEntity = useAppSelector(state => state.inUserGroup.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="inUserGroupDetailsHeading">
          <Translate contentKey="iamdentityApp.inUserGroup.detail.title">InUserGroup</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{inUserGroupEntity.id}</dd>
          <dt>
            <span id="applicationCode">
              <Translate contentKey="iamdentityApp.inUserGroup.applicationCode">Application Code</Translate>
            </span>
          </dt>
          <dd>{inUserGroupEntity.applicationCode}</dd>
          <dt>
            <span id="userBusinessKey">
              <Translate contentKey="iamdentityApp.inUserGroup.userBusinessKey">User Business Key</Translate>
            </span>
          </dt>
          <dd>{inUserGroupEntity.userBusinessKey}</dd>
          <dt>
            <span id="groupCode">
              <Translate contentKey="iamdentityApp.inUserGroup.groupCode">Group Code</Translate>
            </span>
          </dt>
          <dd>{inUserGroupEntity.groupCode}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="iamdentityApp.inUserGroup.status">Status</Translate>
            </span>
          </dt>
          <dd>{inUserGroupEntity.status}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="iamdentityApp.inUserGroup.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {inUserGroupEntity.startDate ? (
              <TextFormat value={inUserGroupEntity.startDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="iamdentityApp.inUserGroup.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {inUserGroupEntity.endDate ? <TextFormat value={inUserGroupEntity.endDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.inUserGroup.importSession">Import Session</Translate>
          </dt>
          <dd>{inUserGroupEntity.importSession ? inUserGroupEntity.importSession.id : ''}</dd>
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment key={cad.id}>
              {foundCustomAttributeID(cad, inUserGroupEntity, locale) && (
                <>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, inUserGroupEntity, locale)}</dd>
                </>
              )}
            </React.Fragment>
          ))}
        </dl>
        <Button id="cancel-save" color="info" onClick={() => navigate(-1)} data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default InUserGroupDetail;
