// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../content/images/jhipster_family_member_2.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* ==========================================================================\nMain page styles\n========================================================================== */\n.hipster {\n  display: inline-block;\n  width: 100%;\n  height: 497px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center top;\n  background-size: contain;\n}\n\n.btn {\n  border: none;\n}\n\n.col-icon {\n  padding: 8px 0;\n  align-content: center;\n  border-top-left-radius: 8px;\n  border-bottom-left-radius: 8px;\n  border-right: 2px solid #fff;\n  color: #000;\n  background: #c1c1c1;\n  font-weight: normal;\n}\n.col-icon svg {\n  margin: 0 !important;\n}", "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/home/home.scss"],"names":[],"mappings":"AAAA;;4EAAA;AAGA;EACE,qBAAA;EACA,WAAA;EACA,aAAA;EACA,wEAAA;EACA,wBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,cAAA;EACA,qBAAA;EACA,2BAAA;EACA,8BAAA;EACA,4BAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,oBAAA;AACJ","sourcesContent":["/* ==========================================================================\r\nMain page styles\r\n========================================================================== */\r\n.hipster {\r\n  display: inline-block;\r\n  width: 100%;\r\n  height: 497px;\r\n  background: url('../../../content/images/jhipster_family_member_2.svg') no-repeat center top;\r\n  background-size: contain;\r\n}\r\n\r\n.btn {\r\n  border: none;\r\n}\r\n\r\n.col-icon {\r\n  padding: 8px 0;\r\n  align-content: center;\r\n  border-top-left-radius: 8px;\r\n  border-bottom-left-radius: 8px;\r\n  border-right: 2px solid #fff;\r\n  color: #000;\r\n  background: #c1c1c1;\r\n  font-weight: normal;\r\n\r\n  svg {\r\n    margin: 0 !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
