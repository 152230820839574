import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './in-employee-delegation.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';

export const InEmployeeDelegationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const inEmployeeDelegationEntity = useAppSelector(state => state.inEmployeeDelegation.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <Row>
      <Col md="12">
        <Card className="jh-card">
          <h2 data-cy="inEmployeeDelegationDetailsHeading">
            <Translate contentKey="iamdentityApp.inEmployeeDelegation.detail.title">InEmployeeDelegation</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="employeeCode">
                <Translate contentKey="iamdentityApp.inEmployeeDelegation.employeeCode">Employee Code</Translate>
              </span>
            </dt>
            <dd>{inEmployeeDelegationEntity.employeeCode}</dd>
            <dt>
              <span id="delegatedJobDefinitionInternalCode">
                <Translate contentKey="iamdentityApp.inEmployeeDelegation.delegatedJobDefinitionInternalCode">
                  Delegated Job Definition Internal Code
                </Translate>
              </span>
            </dt>
            <dd>{inEmployeeDelegationEntity.delegatedJobDefinitionInternalCode}</dd>
            <dt>
              <span id="delegatedOrganizationUnitCode">
                <Translate contentKey="iamdentityApp.inEmployeeDelegation.delegatedOrganizationUnitCode">
                  Delegated Organization Unit Code
                </Translate>
              </span>
            </dt>
            <dd>{inEmployeeDelegationEntity.delegatedOrganizationUnitCode}</dd>
            <dt>
              <span id="validFrom">
                <Translate contentKey="iamdentityApp.inEmployeeDelegation.validFrom">Valid From</Translate>
              </span>
            </dt>
            <dd>
              {inEmployeeDelegationEntity.validFrom ? (
                <TextFormat value={inEmployeeDelegationEntity.validFrom} type="date" format={APP_LOCAL_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="validTo">
                <Translate contentKey="iamdentityApp.inEmployeeDelegation.validTo">Valid To</Translate>
              </span>
            </dt>
            <dd>
              {inEmployeeDelegationEntity.validTo ? (
                <TextFormat value={inEmployeeDelegationEntity.validTo} type="date" format={APP_LOCAL_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="cumulated">
                <Translate contentKey="iamdentityApp.inEmployeeDelegation.cumulated">Cumulated</Translate>
              </span>
            </dt>
            <dd>{inEmployeeDelegationEntity.cumulated ? 'true' : 'false'}</dd>
            {customAttributeDefinitions?.map(cad => (
              <React.Fragment key={cad.id}>
                {foundCustomAttributeID(cad, inEmployeeDelegationEntity, locale) && (
                  <>
                    <dt>
                      <span id={cad.displayName}>{cad.displayName}</span>
                    </dt>
                    <dd>{findCustomAttributeValueByKey(cad, inEmployeeDelegationEntity, locale)}</dd>
                  </>
                )}
              </React.Fragment>
            ))}
          </dl>
          <Button
            style={{ width: 'fit-content' }}
            tag={Link}
            to="/in-employee-delegation"
            replace
            color="info"
            data-cy="entityDetailsBackButton"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default InEmployeeDelegationDetail;
